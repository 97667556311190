import React from 'react'
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Radio,
  Row,
  Skeleton,
  Space,
  Switch,
} from 'antd'
import { createUser, getUserInfo, updateUserInfo } from '../../../api/users'

const UserDrawer = ({ visible, onClose, update, userId }) => {
  const [userData, setUserData] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const [formSubmitLoading, setFormSubmitLoading] = React.useState(false)

  const [form] = Form.useForm()
  const materialsCheckboxOptions = [
    {
      label: 'Marmo',
      value: 'marbre',
    },
    {
      label: 'Granito',
      value: 'granite',
    },
  ]

  const documentVisibilityCheckboxOptions = [
    {
      label: 'Tutti',
      value: 'all',
    },
    {
      label: 'Petrografia',
      value: 'petrography',
    },
  ]

  React.useEffect(() => {
    if (update) {
      setLoading(true)
      getUserInfo(userId)
        .then(res => {
          delete res.data.password
          setUserData(res.data)
          setLoading(false)
        })
        .catch(e => console.log(e))
    } else {
      setUserData({
        userStatus: true,
      })
      setLoading(false)
    }
  }, [])

  const handleSubmit = val => {
    if (update) {
      if (!val.password || val.password.length === 0) {
        delete val.password
      }
      updateUserInfo(userId, val)
        .then(() => {
          setFormSubmitLoading(false)
          onClose()
          message.success('Utente modificato con successo!')
        })
        .catch(e => {
          setFormSubmitLoading(false)
          message.error(e?.response?.data?.message)
        })
    } else {
      createUser(val)
        .then(() => {
          setFormSubmitLoading(false)
          onClose()
          message.success('Utente creato con successo!')
        })
        .catch(e => {
          setFormSubmitLoading(false)
          message.error(e?.response?.data?.message)
        })
    }
  }

  return (
    <Drawer
      title={update ? 'Modifica utente' : 'Aggiungi utente'}
      placement="right"
      onClose={onClose}
      destroyOnClose
      width={'100%'}
      extra={
        <Space>
          <Button onClick={onClose}>Indietro</Button>
          <Button
            onClick={() => {
              setFormSubmitLoading(true)
              form.submit()
            }}
            type="primary"
            loading={formSubmitLoading}>
            Salva
          </Button>
        </Space>
      }
      visible={visible}>
      {loading ? (
        <Skeleton active />
      ) : (
        <Form
          layout="vertical"
          initialValues={userData}
          form={form}
          onFinishFailed={() => setFormSubmitLoading(false)}
          onFinish={handleSubmit}>
          <Row>
            <Col span={update ? 24 : 6}>
              <Form.Item
                label="Username"
                name="username"
                style={{ fontWeight: 'bold' }}
                rules={[
                  { required: true, message: "L'username è obbligatorio" },
                  { min: 2, message: 'Username troppo corto' },
                  { max: 32, message: 'Username troppo lungo' },
                ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={update ? 24 : 6} offset={update ? 0 : 7}>
              <Form.Item
                label="Password"
                style={{ fontWeight: 'bold' }}
                name="password"
                rules={[
                  {
                    required: !update,
                    message: 'La password è obbligatoria',
                  },
                ]}>
                <Input type="password" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Permessi"
            style={{ fontWeight: 'bold' }}
            name="userRole"
            rules={[
              { required: true, message: 'I permessi sono obbligatori.' },
            ]}>
            <Radio.Group>
              <Radio value="admin" style={{ fontWeight: 'normal' }}>
                Admin
              </Radio>
              <Radio value="user" style={{ fontWeight: 'normal' }}>
                User
              </Radio>
              <Radio value="private" style={{ fontWeight: 'normal' }}>
                Private
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Azienda"
            name="companyName"
            rules={[{ required: true, message: 'Il campo è obbligatorio' }]}
            style={{ fontWeight: 'bold' }}>
            <Input />
          </Form.Item>
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item
                label="Nome"
                name="firstName"
                style={{ fontWeight: 'bold' }}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Cognome"
                name="lastName"
                style={{ fontWeight: 'bold' }}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Indirizzo"
            name="address"
            rules={[{ required: true, message: 'Il campo è obbligatorio' }]}
            style={{ fontWeight: 'bold' }}>
            <Input />
          </Form.Item>
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item
                label="Partita IVA"
                name="vatNumber"
                rules={[{ required: true, message: 'Il campo è obbligatorio' }]}
                style={{ fontWeight: 'bold' }}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Telefono"
                name="tel"
                style={{ fontWeight: 'bold' }}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={11}>
              <Form.Item
                label="Email"
                style={{ fontWeight: 'bold' }}
                name="email"
                rules={[{ type: 'email', message: 'La mail non è valida' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={11} offset={2}>
              <Form.Item label="URL" name="url" style={{ fontWeight: 'bold' }}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={3}>
              <Form.Item
                label="Utente attivo"
                name="userStatus"
                style={{ fontWeight: 'bold' }}
                valuePropName="checked">
                <Switch checkedChildren="SI" unCheckedChildren="NO" />
              </Form.Item>
            </Col>
            <Col span={3} offset={1}>
              <Form.Item
                label="Utente associato"
                name="userAssociated"
                style={{ fontWeight: 'bold' }}
                valuePropName="checked">
                <Switch checkedChildren="SI" unCheckedChildren="NO" />
              </Form.Item>
            </Col>
            <Col span={3} offset={1}>
              <Form.Item
                label="Visualizzazione documenti"
                style={{ fontWeight: 'bold' }}
                name="categoryVisibility">
                <Checkbox.Group
                  style={{ fontWeight: 'normal' }}
                  options={materialsCheckboxOptions}
                  defaultValue={[]}
                />
              </Form.Item>
            </Col>
            <Col span={3} offset={1}>
              <Form.Item
                label="Visibilitá documenti"
                style={{ fontWeight: 'bold' }}
                name="documentVisibility">
                <Radio.Group
                  style={{ fontWeight: 'normal' }}
                  options={documentVisibilityCheckboxOptions}
                  defaultValue={'all'}
                />
              </Form.Item>
            </Col>
          </Row>

          <Col span={11}>
            <Form.Item
              label="Note stampa"
              name="notes"
              style={{ fontWeight: 'bold' }}>
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Form>
      )}
    </Drawer>
  )
}
export default UserDrawer
