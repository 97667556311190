import {
  Button,
  Col,
  Collapse,
  Divider,
  Row,
  Segmented,
  Table,
  Typography,
} from 'antd'
import React from 'react'
import { downloadDocument, getDocumentsList } from '../../../../api/documents'
import styles from './index.module.css'
import {
  FilePdfOutlined,
  DownloadOutlined,
  LoadingOutlined,
  TableOutlined,
} from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../redux/user'
import DinModal from './components/dinModal'
import { useNavigate } from 'react-router-dom'
import PublicWrapper from '../../../../components/publicWrapper'
import IstruzioniUso from '../../../../components/istruzioniUso/IstruzioniUso'
import { publicChecker } from '../../../../utils/checker'

const columns = [
  {
    title: 'Finitura',
    dataIndex: 'label',
    key: 'label',
    render: text => <Typography.Text>{text}</Typography.Text>,
  },
  {
    title: 'Angolo di scivolamento medio',
    dataIndex: 'firstValue',
    key: 'firstValue',
    render: text => <Typography.Text>{text}</Typography.Text>,
  },
  {
    title: 'Classe di scivolamento',
    dataIndex: 'secondValue',
    key: 'secondValue',
    render: text => <Typography.Text>{text}</Typography.Text>,
  },
]

const ProvaDin = () => {
  const { user } = useSelector(selectUser)
  const [activeCategory, setActiveCategory] = React.useState(null)
  const [materials, setMaterials] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const navigate = useNavigate()

  const switchComponent = key => {
    switch (key) {
      case '1-1':
        return navigate('/public/ddp')
      case '1-2':
        return navigate('/public/ce')
      case '2-1':
        return navigate('/public/nvl')
      case '2-2':
        return navigate('/public/stpo')
      case '3':
        return navigate('/public/proveinizialitipo')
      case '4':
        return navigate('/public/petrografie')
      case '5':
        return navigate('/public/provadin')
      default:
        return navigate('/public')
    }
  }

  React.useEffect(() => {
    const typesArr = []
    if (user.categoryVisibility.includes('granite'))
      typesArr.push('62f694776b8fac3d4936cd04')
    if (user.categoryVisibility.includes('marbre'))
      typesArr.push('62f694546b8fac3d4936cd01')
    setLoading(true)
    getDocumentsList({
      type: typesArr,
      ...publicChecker(),
    })
      .then(({ data }) => {
        setMaterials(
          data.reduce((group, product) => {
            const category = product.materialId.category
            group[category] = group[category] ?? []
            group[category].push(product)
            return group
          }, {}),
          setLoading(false),
        )
      })
      .catch(e => {
        console.log('Errore fetch materiali', e)
      })
  }, [])

  const materialList = React.useMemo(() => {
    return materials[activeCategory]?.reduce((group, product) => {
      const category = product.materialId.name
      group[category] = group[category] ?? []
      group[category].push(product)
      return group
    }, {})
  }, [activeCategory])

  return (
    <PublicWrapper onChange={key => switchComponent(key)} loading={loading}>
      <div className={styles.moduleWrapper}>
        <Typography.Title level={2}>
          PROVA SCIVOLOSITA&apos; DIN 51130:2014:
        </Typography.Title>
        <Typography.Text style={{ fontSize: 17, color: '#898989' }}>
          La Normativa DIN 51130:2014 è una norma tedesca per la determinazione
          delle proprietà antiscivolo di superfici calpestate, che utilizza il
          metodo della rampa a piedi calzati indossando scarpe
          antinfortunistiche. I campioni di pavimento da esaminare vengono
          posizionati sul piano di un apposito macchinario e successivamente
          bagnati con olio, una persona opportunamente imbragata simula alcuni
          passi su di essi mentre il piano viene inclinato gradualmente sino a
          provocare lo scivolamento della persona che esegue la prova. Il grado
          di inclinazione che ha causato lo scivolamento determina la
          classificazione &quot;R&quot; della pavimentazione: da R9, che indica
          il coefficiente d&apos; attrito minimo e pertanto la pavimentazione è
          ideale per aree interne, a R13 dove il coefficiente d&apos;attrito è
          molto elevato e la pavimentazione sarà adatta ad aree con grosse
          quantità di grassi come la lavorazione degli alimenti.
        </Typography.Text>
        <Divider />
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          Selezionare la categoria desiderata
        </Typography.Title>
        <Segmented
          block
          options={[
            user.categoryVisibility.includes('granite') && {
              label: 'Graniti (Vedi elenco)',
              value: 'granito',
            },
            user.categoryVisibility.includes('marbre') && {
              label: 'Marmi (Vedi elenco)',
              value: 'marmo',
            },
          ].filter(obj => obj)}
          onChange={val => setActiveCategory(val)}
          value={activeCategory}
        />
        {activeCategory && (
          <Collapse
            ghost
            style={{
              paddingLeft: activeCategory === 'marmo' ? '50%' : 0,
            }}>
            {materialList &&
              Object.keys(materialList)?.map(currentMaterial => {
                return (
                  <Collapse.Panel
                    header={
                      <Typography.Link strong style={{ fontSize: 18 }}>
                        {currentMaterial}
                      </Typography.Link>
                    }
                    key={currentMaterial}>
                    <Col style={{ marginLeft: 24, marginTop: -24 }}>
                      {materialList[currentMaterial]
                        ?.sort(
                          (a, b) => parseInt(a?.order) - parseInt(b?.order),
                        )
                        ?.map((currentDocument, index) => {
                          return (
                            <>
                              <DocumentRow
                                activeCategory={activeCategory}
                                currentDocument={currentDocument}
                                key={currentDocument._id}
                                showDin={
                                  index ===
                                  materialList[currentMaterial].length - 1
                                }
                              />
                            </>
                          )
                        })}
                    </Col>
                  </Collapse.Panel>
                )
              })}
          </Collapse>
        )}
      </div>
    </PublicWrapper>
  )
}

const DocumentRow = ({ currentDocument, activeCategory, showDin }) => {
  const [loading, setLoading] = React.useState(false)
  const [modalVisible, setModalVisible] = React.useState(false)

  return (
    <Row
      span={24}
      key={currentDocument._id}
      className={
        activeCategory === 'marmo'
          ? styles.documentRow_marmi
          : styles.documentRow
      }>
      <DinModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        currentDocument={currentDocument}
      />
      <div style={{ justifyContent: 'center' }}>
        <FilePdfOutlined style={{ fontSize: 24 }} />
        <Typography.Text strong style={{ marginLeft: 8, fontSize: 16 }}>
          {currentDocument.name}
        </Typography.Text>
      </div>
      <div>
        {loading ? (
          <LoadingOutlined style={{ fontSize: 24 }} />
        ) : (
          <Button
            size="middle"
            loading={loading}
            icon={<DownloadOutlined style={{ fontSize: 20 }} />}
            type="primary"
            onClick={() => {
              setLoading(true)
              downloadDocument(currentDocument._id, currentDocument.name)
                .then(() => {
                  setLoading(false)
                })
                .catch(() => null)
            }}
          />
        )}
      </div>
      {showDin && (
        <Table
          columns={columns}
          style={{ marginTop: 16, width: '100%' }}
          size="small"
          dataSource={currentDocument?.dinShowcase}
          pagination={false}
        />
      )}
    </Row>
  )
}

export default ProvaDin
