import {
  DeploymentUnitOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PaperClipOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { LogoWhite } from '../../assets'
import { store } from '../../redux/store'
import user, { logout } from '../../redux/user'
import { adminChecker, privateChecker } from '../../utils/checker'
import styled from 'styled-components'
import('./wrapper.css')

const { Header, Content, Sider } = Layout

const NavbarContainer = styled.div`
  #wrapper_sider .ant-layout-sider-children {
    background-color: #1f4d80 !important;
  }
  #wrapper-menu .ant-menu-item {
    background-color: #1f4d80 !important;
  }
  #wrapper-menu {
    background-color: #1f4d80 !important;
  }
  #wrapper-menu .ant-menu-item-selected {
    background-color: #1891ff !important;
  }
`

const Wrapper = props => {
  const navigate = useNavigate()
  const location = useLocation()
  const [collapsed, setCollapsed] = React.useState(false)

  return (
    <NavbarContainer>
      <Layout style={{ minHeight: '100vh' }} theme="light">
        <Sider
          collapsible
          collapsed={collapsed}
          trigger={null}
          width={300}
          id="wrapper_sider">
          <div className="logo">
            <img src={LogoWhite} width={70} height={70} />
          </div>
          <Menu
            id="wrapper-menu"
            theme="dark"
            mode="inline"
            defaultSelectedKeys={props.selectedItem}
            items={[
              {
                key: '0',
                icon: <HomeOutlined />,
                label: 'Home',
                onClick: () => {
                  navigate('/home')
                },
              },
              {
                key: '1',
                icon: <PaperClipOutlined />,
                label: 'Visualizza certificati online',
                onClick: () => {
                  navigate('/public')
                },
              },
              adminChecker() && {
                key: '2',
                icon: <UserOutlined />,
                label: 'Aziende',
                onClick: () => navigate('/aziende'),
              },
              {
                key: '3',
                icon: <DeploymentUnitOutlined />,
                label: 'Materiali',
                onClick: () => navigate('/materiali'),
              },
              {
                key: '4',
                icon: <PaperClipOutlined />,
                label: 'Documenti',
                onClick: () => navigate('/documenti'),
              },
            ]}
          />
        </Sider>
        <Layout>
          <Header
            className="site-layout-sub-header-background"
            style={{ padding: 0 }}>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
              },
            )}
            <LogoutOutlined
              className="trigger logout"
              onClick={() => {
                store.dispatch(logout())
                navigate('/', { replace: true })
              }}
            />
          </Header>
          <Content
            style={{
              margin: '24px 16px 0',
            }}>
            <div
              className="site-layout-background"
              style={{
                padding: 24,
                minHeight: 360,
              }}>
              {props.children}
            </div>
          </Content>
        </Layout>
      </Layout>
    </NavbarContainer>
  )
}

export default Wrapper
