import { Modal } from 'antd'
import React from 'react'

const IstruzioniUso = ({ visible, onCancel, children }) => {
  // States

  return (
    <Modal
      title="ISTRUZIONI D'USO"
      open={visible}
      width={1000}
      onCancel={onCancel}
      style={{ overflowY: 'auto' }}
      footer={null}>
      {children}
    </Modal>
  )
}

export default IstruzioniUso
