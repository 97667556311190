import { Button, Checkbox, Col, Divider, Modal, Typography, Input } from 'antd'
import React from 'react'
import { downloadMaterialPost } from '../../../../../api/documents'

const CEModal = ({
  visible,
  onClose,
  type,
  lang,
  material,
  activeCategory,
}) => {
  // States
  const [checkOptions, setCheckOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  const label = () => {
    switch (type) {
      case 'est':
        return `Esterni - ${lang.toUpperCase()}`
      case 'int':
        return `Interni - ${lang.toUpperCase()}`
      case 'pav':
        return `Pavimentazioni Stradali - ${lang.toUpperCase()}`
      default:
        return ''
    }
  }

  const CHECKBOX_OPTIONS = () => {
    switch (type) {
      case 'est':
        return [
          {
            value: 'first',
            label:
              'EN 12057 - Marmette modulari di pietra naturale per pavimentazioni e scale.\n Impiego finale: Pavimentazioni esterne',
          },
          {
            value: 'second',
            label:
              'EN 12057 - Marmette modulari di pietra naturale per rivestimenti.\n Impiego finale: Rivestimenti di pareti e soffitti esterni',
          },
          {
            value: 'third',
            label:
              'EN 12058 - Lastre di pietra naturale per pavimentazioni e scale.\n Impiego finale: Pavimentazioni esterne',
          },
          {
            value: 'fourth',
            label:
              'EN 1469 - Lastre di pietra naturale per rivestimenti.\n Impiego finale: Rivestimenti di pareti e soffitti esterni',
          },
        ]
      case 'int':
        return [
          {
            value: 'first',
            label:
              'EN 12057 - Marmette modulari di pietra naturale per pavimentazioni e scale.\n Impiego finale: Pavimentazioni interne',
          },
          {
            value: 'second',
            label:
              'EN 12057 - Marmette modulari di pietra naturale per rivestimenti.\n Impiego finale: Rivestimenti di pareti e soffitti interni',
          },
          {
            value: 'third',
            label:
              'EN 12058 - Lastre di pietra naturale per pavimentazioni e scale.\n Impiego finale: Pavimentazioni interne',
          },
          {
            value: 'fourth',
            label:
              'EN 1469 - Lastre di pietra naturale per rivestimenti.\n Impiego finale: Rivestimenti di pareti e soffitti interni',
          },
        ]
      case 'pav':
        return [
          {
            value: 'first',
            label:
              'EN 1341 - Lastre di pietra naturale per pavimentazioni esterne.\n Impiego finale: Pavimentazioni esterne per uso pedonale e veicolare',
          },
          {
            value: 'second',
            label:
              'EN 1342 - Cubetti di pietra naturale per pavimentazione esterne.\n Impiego finale: Pavimentazioni esterne per uso pedonale e veicolare',
          },
          {
            value: 'third',
            label:
              'EN 1343 - Cordolo di pietra naturale per pavimentazioni esterne.\n Impiego finale: Pavimentazioni esterne aree pedonali e carrabili',
          },
        ]
      default:
        return []
    }
  }

  const download = () => {
    setLoading(true)
    downloadMaterialPost(
      material._id,
      'ce',
      checkOptions.reduce((prev, obj) => {
        prev[obj] = true
        return prev
      }, {}),
      {
        lang,
        type,
      },
    )
      .then(() => {
        setLoading(false)
      })
      .catch(() => null)
  }

  return (
    <Modal
      title="Generazione documento"
      open={visible}
      footer={null}
      onCancel={() => {
        setLoading(false)
        setCheckOptions([])
        onClose()
      }}
      width={'50%'}>
      {visible && (
        <>
          <Typography.Title level={4}>
            Hai selezionato la prova CE - {label()} del {activeCategory}{' '}
            {material.name}.<br /> Il tempo di creazione del PDF dipende dalla
            quantità di pagine e dalla velocità di connessione ad internet.
          </Typography.Title>
          <Divider />
          <Checkbox.Group
            value={checkOptions}
            style={{ width: '100%' }}
            onChange={val => setCheckOptions(val)}>
            {CHECKBOX_OPTIONS().map(current => {
              return (
                <Col span={24} key={current.value}>
                  <Checkbox value={current.value}>
                    <Typography.Text
                      style={{
                        fontSize: 18,
                        whiteSpace: 'pre-line',
                        verticalAlign: 'bottom',
                      }}>
                      {current.label}
                    </Typography.Text>
                  </Checkbox>
                </Col>
              )
            })}
          </Checkbox.Group>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              loading={loading}
              onClick={() => download()}
              type="primary"
              style={{ width: '40%' }}>
              SCARICA
            </Button>
          </div>
        </>
      )}
    </Modal>
  )
}

export default CEModal
