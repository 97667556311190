import {
  Button,
  Col,
  Collapse,
  Divider,
  Row,
  Segmented,
  Select,
  Typography,
} from 'antd'
import React from 'react'
import { downloadMaterial } from '../../../../api/documents'
import styles from './index.module.css'
import { FolderViewOutlined, RightOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../redux/user'
import { getAllMaterials } from '../../../../api/materials'
import CEModal from './components/modal'
import { useNavigate } from 'react-router-dom'
import PublicWrapper from '../../../../components/publicWrapper'
import IstruzioniUso from '../../../../components/istruzioniUso/IstruzioniUso'
import { publicChecker } from '../../../../utils/checker'

const DOCTYPE = 'ddp'
const { Option } = Select

const DDP = () => {
  const { user } = useSelector(selectUser)
  const [activeCategory, setActiveCategory] = React.useState(null)
  const [materials, setMaterials] = React.useState([])
  const [lang, setLang] = React.useState('it')
  const [istrUso, setIstrUso] = React.useState(false)
  const [CEModalConf, setCEModalConf] = React.useState({
    visible: false,
  })
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)

  const switchComponent = key => {
    switch (key) {
      case '1-1':
        return navigate('/public/ddp')
      case '1-2':
        return navigate('/public/ce')
      case '2-1':
        return navigate('/public/nvl')
      case '2-2':
        return navigate('/public/stpo')
      case '3':
        return navigate('/public/proveinizialitipo')
      case '4':
        return navigate('/public/petrografie')
      case '5':
        return navigate('/public/provadin')
      default:
        return navigate('/public')
    }
  }

  React.useEffect(() => {
    const typesArr = []
    if (user.categoryVisibility.includes('granite')) typesArr.push('granito')
    if (user.categoryVisibility.includes('marbre')) typesArr.push('marmo')
    setLoading(true)
    getAllMaterials({
      category: typesArr,
      ...publicChecker(),
    })
      .then(({ data }) => {
        setMaterials(data.data)
        setLoading(false)
      })
      .catch(e => {
        console.log('Errore fetch materiali', e)
      })
  }, [])

  const materialList = React.useMemo(() => {
    return materials.filter(obj => obj.category === activeCategory)
  }, [activeCategory])
  return (
    <PublicWrapper onChange={key => switchComponent(key)} loading={loading}>
      <IstruzioniUso visible={istrUso} onCancel={() => setIstrUso(false)}>
        <Typography.Text style={{ fontWeight: 'bold', color: '#797979' }}>
          La DoP ha validità 10 anni e deve essere consegnata a ciascun cliente
          che acquista quel determinato prodotto (sempre la stessa DoP con
          medesimo numero e data di emissione ad ogni cliente). La numerazione
          della DoP è già stata assegnata automaticamente (sia il numero che la
          data) seguendo la seguente logica:
          <br />
          <br />{' '}
          <Typography.Text style={{ fontWeight: 'bold', color: '#000' }}>
            {' '}
            DICHIARAZIONE DI PRESTAZIONE N° 096/2013-07-01/A
          </Typography.Text>
          <br />
          <br /> Dove:
          <br />
          <Typography.Text style={{ color: '#000' }}>096</Typography.Text> sta
          per il numero assegnato al materiale (in questo caso Arabescato)
          <br />
          <Typography.Text style={{ color: '#000' }}>
            2013-07-01
          </Typography.Text>{' '}
          è la data di partenza dell’ entrata in vigore della DoP (un materiale
          che verrà testato ed immesso sul mercato il 15 Settembre avrà una DoP
          datata …../2013-09-15) <br />
          <Typography.Text style={{ color: '#000' }}>A</Typography.Text>{' '}
          corrisponde al tipo di prodotto venduto. Nella maschera di generazione
          della DoP sono state riservate alla compilazione degli assocati solo
          alcune righe,il resto esce tutto in automatico:
          <br />
          <br /> La prima riga (Codice di identificazione del prodotto) riguarda
          la{' '}
          <Typography.Text style={{ color: '#000' }}>
            descrizione del materiale come indicato in fattura
          </Typography.Text>{' '}
          (se avete anche una numerazione di riferimento o una decodifica
          interna potete indicare anche quella, al fine di garantire la
          rintracciabilità del prodotto). <br />
          La seconda riga è stata aggiunta appositamente per i{' '}
          <Typography.Text style={{ color: '#000' }}>
            riferimenti alla fattura
          </Typography.Text>
          .
          <br />
          <br /> Spuntare il tipo di prodotto per il quale va emessa la DoP:
          <br />
          <br />{' '}
          <Typography.Text style={{ color: '#000' }}>
            A = Marmette di pietra naturale destinate all’ uso di
            “pavimentazione interna”
          </Typography.Text>
          <br /> come da UNI EN 12057:2005 <br />
          <Typography.Text style={{ color: '#000' }}>
            B = Marmette di pietra naturale destinate all’ uso di
            “pavimentazione esterna”
          </Typography.Text>
          <br /> come da UNI EN 12057:2005 <br />
          <Typography.Text style={{ color: '#000' }}>
            C = Marmette di pietra naturale destinate all’ uso di “rivestimenti
            interni”
          </Typography.Text>
          <br /> come da UNI EN 12057:2005
          <br />
          <Typography.Text style={{ color: '#000' }}>
            {' '}
            D = Marmette di pietra naturale destinate all’ uso di “rivestimenti
            esterni”
          </Typography.Text>
          <br /> come da UNI EN 12057:2005
          <br />
          <Typography.Text style={{ color: '#000' }}>
            {' '}
            E = Lastre di pietra naturale destinate all’ uso di “pavimentazione
            interna”
          </Typography.Text>
          <br /> come da UNI EN 12058:2005
          <br />
          <Typography.Text style={{ color: '#000' }}>
            {' '}
            F = Lastre di pietra naturale destinate all’ uso di “pavimentazione
            esterna”
          </Typography.Text>
          <br /> come da UNI EN 12058:2005
          <br />
          <Typography.Text style={{ color: '#000' }}>
            {' '}
            G = Lastre di pietra naturale destinate all’ uso di “rivestimenti
            interni”
          </Typography.Text>
          <br /> come da UNI EN 1469:2005
          <br />
          <Typography.Text style={{ color: '#000' }}>
            {' '}
            H = Lastre di pietra naturale destinate all’ uso di “rivestimenti
            esterni”
          </Typography.Text>
          <br /> come da UNI EN 1469:2005
          <br />
          <Typography.Text style={{ color: '#000' }}>
            {' '}
            I = Lastre di pietra naturale destinate all’uso di “pavimentazione
            esterna”
          </Typography.Text>
          <br />
          come da UNI EN 1341:2013
          <br />
          <Typography.Text style={{ color: '#000' }}>
            L = Cubetti di pietra naturale destinate all’uso di “pavimentazione
            esterna”
          </Typography.Text>
          <br /> come da UNI EN 1342:2013
          <br />
          <Typography.Text style={{ color: '#000' }}>
            {' '}
            M = Cordoli di pietra naturale destinate all’uso di “pavimentazione
            esterna”
          </Typography.Text>
          <br /> come da UNI EN 1343:2013
          <br />
          <br /> Nella riga “Firmato a nome e per conto del fabbricante” vanno
          inseriti nome e cognome del fabbricante oppure della persona
          incaricata ad emettere la DoP con relativo ruolo in azienda (es.:
          Mario Rossi, Direttore Amminstrativo).
          <br />
          <br />
          Inserire Luogo e Data del rilascio
        </Typography.Text>
      </IstruzioniUso>
      <div className={styles.moduleWrapper}>
        <CEModal
          {...CEModalConf}
          onClose={() =>
            setCEModalConf({
              visible: false,
            })
          }
          activeCategory={activeCategory}
        />
        <Typography.Title level={2}>
          DICHIARAZIONE DI PRESTAZIONE
        </Typography.Title>
        <Typography.Text style={{ fontSize: 17, color: '#898989' }}>
          Il fabbricante deve redigere una Dichiarazione di Prestazione (DoP)
          all’ atto dell’ immissione di un prodotto da costruzione sul mercato
          dell’ Unione Europea, quando lo stesso rientra nell’ ambito di
          applicazione di una Norma Armonizzata.
          <br /> Nel redigere la DoP il fabbricante si assume la responsabilità
          della conformità del prodotto da costruzione a tale prestazione
          dichiarata.
          <br /> La DoP può essere fornita in forma cartacea o su supporto
          elettronico.
          <br /> La DoP è fornita nella lingua o nelle lingue richieste dallo
          Stato in cui il prodotto è messo a disposizione.
        </Typography.Text>
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Link
            style={{ fontSize: 18, fontWeight: 'bold' }}
            onClick={() => setIstrUso(true)}>
            ISTRUZIONI D&apos;USO
          </Typography.Link>
        </div>
        <Divider />
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          Selezionare la lingua desiderata
        </Typography.Title>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Select
            defaultValue="it"
            value={lang}
            style={{ width: '25%' }}
            onChange={val => setLang(val)}>
            <Option value="it">Italiano</Option>
            <Option value="en">Inglese</Option>
            <Option value="de">Tedesco</Option>
            <Option value="fr">Francese</Option>
          </Select>
        </div>

        <Divider />
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          Selezionare la categoria desiderata
        </Typography.Title>
        <Segmented
          block
          options={[
            user.categoryVisibility.includes('granite') && {
              label: 'Graniti (Vedi elenco)',
              value: 'granito',
            },
            user.categoryVisibility.includes('marbre') && {
              label: 'Marmi (Vedi elenco)',
              value: 'marmo',
            },
          ].filter(obj => obj)}
          onChange={val => setActiveCategory(val)}
          value={activeCategory}
        />
        {activeCategory && (
          <Collapse
            ghost
            style={{
              paddingLeft: activeCategory === 'marmo' ? '50%' : 0,
            }}>
            {materialList &&
              materialList
                ?.sort((a, b) => parseInt(a?.order) - parseInt(b?.order))
                ?.map(currentMaterial => {
                  return (
                    <Collapse.Panel
                      key={currentMaterial._id}
                      header={
                        <Typography.Link strong style={{ fontSize: 18 }}>
                          {currentMaterial.name}
                        </Typography.Link>
                      }>
                      <Col style={{ marginLeft: 24, marginTop: -24 }}>
                        <TypeRow
                          activeCategory={activeCategory}
                          label={`Dichiarazione di prestazione - ${lang.toUpperCase()}`}
                          onClick={() =>
                            setCEModalConf({
                              visible: true,
                              lang,
                              material: currentMaterial,
                            })
                          }
                        />
                      </Col>
                    </Collapse.Panel>
                  )
                })}
          </Collapse>
        )}
      </div>
    </PublicWrapper>
  )
}

const TypeRow = ({ label, onClick, activeCategory }) => {
  return (
    <Row
      span={24}
      className={
        activeCategory === 'marmo'
          ? styles.documentRow_marmi
          : styles.documentRow
      }>
      <div style={{ justifyContent: 'center' }}>
        <FolderViewOutlined style={{ fontSize: 24 }} />
        <Typography.Text strong style={{ marginLeft: 8, fontSize: 16 }}>
          {label}
        </Typography.Text>
      </div>
      <div>
        <Button
          size="middle"
          icon={<RightOutlined style={{ fontSize: 20 }} />}
          type="primary"
          onClick={onClick}
        />
      </div>
    </Row>
  )
}

export default DDP
