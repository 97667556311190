import { store } from '../redux/store'
import { selectUser } from '../redux/user'

export const adminChecker = () => {
  const { user } = selectUser(store.getState())
  if (user.userRole === 'user' || user.userRole === 'private') {
    return false
  } else {
    return true
  }
}

export const privateChecker = () => {
  const { user } = selectUser(store.getState())
  if (user.userRole === 'private') {
    return true
  } else {
    return false
  }
}

export const getPrivateId = () => {
  const { user } = selectUser(store.getState())
  if (user.userRole === 'private') {
    return user._id
  } else {
    return ''
  }
}

export const publicChecker = () => {
  const { user } = selectUser(store.getState())
  switch (user.userRole) {
    case 'private':
      return { userId: [user._id, ''] }
    case 'admin':
    case 'user':
      return { userId: '' }
    default:
      return { userId: '' }
  }
}
