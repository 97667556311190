import { combineReducers, configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userReducer from './user'

const reducers = combineReducers({
  user: userReducer,
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [logger],
})

export const persistor = persistStore(store)

export const awaitRehydrate = async () => {
  const state = store.getState()
  if (!state._persist.rehydrated) {
    console.log('not Rehydrate')
    await timeout(10)
    return awaitRehydrate()
  } else {
    console.log('Rehydrate')
    return true
  }
}

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
