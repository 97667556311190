import { Typography, Descriptions, Layout, Col, Row, Menu, Spin } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { logout, selectUser } from '../../redux/user'
import 'antd/dist/antd.css'
import {
  BackwardOutlined,
  HomeOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { store } from '../../redux/store'
import COLORS from '../../const/colors'
import styled from 'styled-components'
import('./style.css')

const { Text } = Typography

const NavbarContainer = styled.div`
  #public-menu .ant-menu-item {
    background-color: #ffffff !important;
  }
  #public-menu .ant-menu-item-selected {
    background-color: #ffffff !important;
  }

  #public-menu .ant-menu-sub {
    background-color: #ebedf4 !important;
  }

  #public-menu .ant-menu-sub .ant-menu-item {
    background-color: #ebedf4 !important;
  }
  #public-menu .ant-descriptions-item-label {
    background-color: #ebedf4 !important;
  }
`

const PublicWrapper = ({ children, onChange, changeStyle, loading }) => {
  const { user } = useSelector(selectUser)
  const navigate = useNavigate()
  const location = useLocation()
  const [activeMenu, setActiveMenu] = React.useState(null)

  const renderTitle = () => {
    return (
      <NavbarContainer>
        <div
          style={{
            padding: '12px 16px',
            background: COLORS.darkBlue,
            border: 1,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <HomeOutlined
            style={{ fontSize: 42, color: 'whitesmoke', cursor: 'pointer' }}
            onClick={() => navigate('/public')}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text
              style={{
                fontSize: 32,
                fontWeight: 'bolder',
                textTransform: 'uppercase',
                letterSpacing: '.1em',
                color: 'whitesmoke',
              }}>
              Azienda: {user.companyName}
            </Text>
            {user.userRole !== 'user' && (
              <Typography.Link
                onClick={() => {
                  navigate('/home')
                }}
                style={{ color: 'whitesmoke', textDecoration: 'underline' }}>
                Ritorna al pannello di amministrazione
              </Typography.Link>
            )}
          </div>

          <LogoutOutlined
            className="trigger logout"
            style={{
              fontSize: 24,
              color: 'whitesmoke',
            }}
            onClick={() => {
              store.dispatch(logout())
              navigate('/', { replace: true })
            }}
          />
        </div>
      </NavbarContainer>
    )
  }

  const changeItem = ({ key }) => {
    setActiveMenu(key)
    onChange(key)
  }

  const labelRender = (title, sub) => (
    <Row>
      <Col span={24}>
        <Text>{title}</Text>
      </Col>
      <Col span={24}>
        <Text>{sub}</Text>
      </Col>
    </Row>
  )

  const MENU_ITEMS =
    user?.documentVisibility === 'all'
      ? [
          {
            key: '1',
            label: 'CERTIFICAZIONI CE ( da allegare ai documenti di Vendita )',
            className: 'menu-row',
            children: [
              {
                key: '1-1',
                label: 'DICHIARAZIONE DI PRESTAZIONE',
                className: 'menu-row',
                onClick: changeItem,
              },
              {
                key: '1-2',
                label: 'MARCATURA CE',
                className: 'menu-row',
                onClick: changeItem,
              },
            ],
          },
          {
            key: '2',
            label: 'SCHEDE TECNICHE',
            className: 'menu-row',
            children: [
              {
                key: '2-1',
                label: 'NORMA VOLONTARIA PER LASTRE',
                className: 'menu-row',
                onClick: changeItem,
              },
              {
                key: '2-2',
                label: 'SCHEDA TECNICA PRE-ORDINE',
                className: 'menu-row',
                onClick: changeItem,
              },
            ],
          },
          {
            key: '3',
            label: 'PROVE INIZIALI DI TIPO',
            className: 'menu-row',
            onClick: changeItem,
          },
          {
            key: '4',
            label: 'PETROGRAFIE',
            className: 'menu-row',
            onClick: changeItem,
          },
          {
            key: '5',
            label: "PROVA SCIVOLOSITA' DIN 51130:2014",
            className: 'menu-row',
            onClick: changeItem,
          },
        ]
      : [
          {
            key: '4',
            label: 'PETROGRAFIE',
            className: 'menu-row',
            onClick: changeItem,
          },
        ]

  return (
    <NavbarContainer>
      <Layout style={{ minHeight: '100%' }} className="bg">
        {renderTitle()}
        <div
          style={{
            width: '100%',
            backgroundColor: 'transparent',
            height: 4,
          }}></div>
        <Row className="public_bottom">
          <Col span={6} style={{ background: COLORS.darkBlue }}>
            <Descriptions
              size={'small'}
              bordered
              column={1}
              style={{ background: 'white' }}>
              <Descriptions.Item label="Incaricato:">{`${user.firstName} ${user.lastName}`}</Descriptions.Item>
              <Descriptions.Item label="Indirizzo:">
                {user.address}
              </Descriptions.Item>
              <Descriptions.Item label="P. IVA:">
                {user.vatNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Telefono:">
                {user.tel}
              </Descriptions.Item>
              <Descriptions.Item label="E-mail">{user.email}</Descriptions.Item>
            </Descriptions>
            <div
              style={{
                width: '100%',
                backgroundColor: 'transparent',
                height: 4,
              }}></div>
            <Menu
              id="public-menu"
              theme="light"
              mode="inline"
              style={{ height: '100%' }}
              activeKey={activeMenu}
              items={MENU_ITEMS}
            />
          </Col>
          {loading ? (
            <Col span={18} className="public_loader">
              <Spin size="large" />
            </Col>
          ) : (
            <Col span={18} className="public_children">
              {children}
            </Col>
          )}
        </Row>
      </Layout>
    </NavbarContainer>
  )
}

export default PublicWrapper
