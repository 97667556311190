import { Button, Checkbox, Col, Divider, Image, Modal, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { downloadMaterialPost } from '../../../../../api/documents'
import { getMaterialImage } from '../../../../../api/materials'
import { selectUser } from '../../../../../redux/user'

const PhotoModal = ({ visible, onClose, material, noInfo }) => {
  // States
  const [image, setImage] = React.useState(undefined)
  const { user } = useSelector(selectUser)

  React.useEffect(() => {
    if (visible && material?.fileId) {
      getMaterialImage(material?._id)
        .then(({ data }) => setImage(data))
        .catch(e => {
          console.warn(e)
          setImage(undefined)
        })
    }
  }, [visible])
  return (
    <Modal
      title={material?.name}
      open={visible}
      footer={null}
      onCancel={() => {
        onClose()
      }}
      width={'50%'}>
      {visible && (
        <>
          {!noInfo && (
            <>
              <Typography.Title level={5}>{user?.companyName}</Typography.Title>
              {user?.userAssociated && (
                <Typography.Text>Associato ASMAVE</Typography.Text>
              )}

              <Divider />
            </>
          )}
          {image ? (
            <Image width={'100%'} src={image} />
          ) : (
            <Typography.Text type="danger">
              Non è presente nessuna immagine!
            </Typography.Text>
          )}
        </>
      )}
    </Modal>
  )
}

export default PhotoModal
