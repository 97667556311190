import { Modal, Table, Typography } from 'antd'
import React from 'react'

const DinModal = ({ visible, onClose, currentDocument }) => {
  const columns = [
    {
      title: 'Finitura',
      dataIndex: 'label',
      key: 'label',
      render: text => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: 'Angolo di scivolamento medio',
      dataIndex: 'firstValue',
      key: 'firstValue',
      render: text => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: 'Classe di scivolamento',
      dataIndex: 'secondValue',
      key: 'secondValue',
      render: text => <Typography.Text>{text}</Typography.Text>,
    },
  ]

  return (
    <Modal
      title={`${currentDocument.name}`}
      visible={visible}
      onOk={onClose}
      width="50%"
      style={{ minWidth: '400px' }}
      onCancel={onClose}
      footer={null}
      okText="Chiudi">
      <Table
        columns={columns}
        size="small"
        dataSource={currentDocument.tableRows}
        pagination={false}
      />
    </Modal>
  )
}

export default DinModal
