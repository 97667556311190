/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  Radio,
  Row,
  Skeleton,
  Space,
  Typography,
  Upload,
} from 'antd'
import {
  createMaterial,
  getMaterialInfo,
  updateMaterialInfo,
  uploadMaterialImage,
} from '../../../api/materials'
import { InboxOutlined } from '@ant-design/icons'
import PhotoModal from '../../public/components/marcaturaCE/components/PhotoModal'

const MaterialDrawer = ({ visible, onClose, update, materialId }) => {
  const [materialData, setMaterialData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [formSubmitLoading, setFormSubmitLoading] = React.useState(false)
  const [photo, setPhoto] = React.useState(false)

  const [image, setImage] = React.useState([])

  const [form] = Form.useForm()

  React.useEffect(() => {
    if (update) {
      setLoading(true)
      getMaterialInfo(materialId)
        .then(res => {
          setMaterialData(res.data)
          setLoading(false)
        })
        .catch(e => console.log(e))
    }
  }, [])

  const uploadImage = id => {
    if (image.length === 0) {
      onClose()
      return
    }
    uploadMaterialImage(id, image)
      .then(() => {
        if (update) message.success('Materiale modificato con successo!')
        else message.success('Materiale creato con successo!')
        setFormSubmitLoading(false)
        onClose()
      })
      .catch(e => console.log(e))
  }

  const handleSubmit = val => {
    if (update) {
      updateMaterialInfo(materialId, val)
        .then(res => {
          uploadImage(res.data?._id)
        })
        .catch(e => {
          setFormSubmitLoading(false)
          message.error(e?.response?.data?.message)
        })
    } else {
      createMaterial(val)
        .then(res => {
          uploadImage(res.data?._id)
        })
        .catch(e => {
          setFormSubmitLoading(false)
          message.error(e?.response?.data?.message)
        })
    }
  }

  return (
    <Drawer
      title={update ? 'Modifica materiale' : 'Aggiungi materiale'}
      placement="right"
      onClose={onClose}
      destroyOnClose
      width={'100%'}
      extra={
        <Space>
          <Button onClick={onClose}>Indietro</Button>
          <Button
            onClick={() => {
              setFormSubmitLoading(true)
              form.submit()
            }}
            type="primary"
            loading={formSubmitLoading}>
            Salva
          </Button>
        </Space>
      }
      visible={visible}>
      {loading ? (
        <Skeleton active />
      ) : (
        <Form
          layout="vertical"
          initialValues={materialData}
          form={form}
          onFinishFailed={() => setFormSubmitLoading(false)}
          onFinish={handleSubmit}>
          <Form.Item
            label="A - Nome"
            style={{ fontWeight: 'bold' }}
            name="name"
            rules={[{ required: true, message: 'Il nome è obbligatorio' }]}>
            <Input />
          </Form.Item>
          <Typography.Text level={5} style={{ fontWeight: 'bold' }}>
            B - Foto
          </Typography.Text>
          {materialData.fileId && (
            <>
              <PhotoModal
                visible={photo}
                material={materialData}
                noInfo
                onClose={() => setPhoto(false)}
              />
              <br />
              <Typography.Text level={3} style={{ color: 'green' }}>
                E' già presente un'immagine associata a questo materiale{' -> '}
                <Typography.Link onClick={() => setPhoto(true)}>
                  VEDI FOTO
                </Typography.Link>
              </Typography.Text>
              <br />
              <br />
            </>
          )}
          <Upload.Dragger
            name="images"
            accept=".png,.jpg"
            fileList={image}
            beforeUpload={image => {
              console.log(image, typeof image)
              setImage([image])
              return false
            }}>
            <p className="ant-upload-text">
              Clicca o trascina un immagine per caricarla.
            </p>
          </Upload.Dragger>
          <Form.Item
            style={{ marginTop: 32, fontWeight: 'bold' }}
            label="C - Tipo"
            name="category"
            rules={[{ required: true, message: 'Il tipo è obbligatorio' }]}>
            <Radio.Group>
              <Radio value="marmo" style={{ fontWeight: 'normal' }}>
                Marmo
              </Radio>
              <Radio value="granito" style={{ fontWeight: 'normal' }}>
                Granito
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item
                label="D - Codice"
                name="materialCode"
                style={{ fontWeight: 'bold' }}
                rules={[
                  { required: true, message: 'Il codice è obbligatorio' },
                ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="E - Codice prestazione"
                style={{ fontWeight: 'bold' }}
                name="performanceCode"
                rules={[
                  {
                    required: true,
                    message: 'Il codice prestazione è obbligatorio',
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Typography.Text level={5} style={{ fontWeight: 'bold' }}>
            F - Definizione petrografica
          </Typography.Text>
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item
                label="Nome IT"
                name={['nameTranslation', 'it']}
                rules={[
                  { required: true, message: 'Questo campo è obbligatorio' },
                ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label="Nome EN" name={['nameTranslation', 'en']}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item label="Nome DE" name={['nameTranslation', 'de']}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label="Nome FR" name={['nameTranslation', 'fr']}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Typography.Text level={5} style={{ fontWeight: 'bold' }}>
            G - Colore
          </Typography.Text>
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item
                label="Colore IT"
                name={['colorTranslation', 'it']}
                rules={[
                  { required: true, message: 'Questo campo è obbligatorio' },
                ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label="Colore EN" name={['colorTranslation', 'en']}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item label="Colore DE" name={['colorTranslation', 'de']}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label="Colore FR" name={['colorTranslation', 'fr']}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="H - Paese"
            name="location"
            rules={[
              {
                required: true,
                message: 'Il paese è obbligatorio',
              },
            ]}
            style={{ fontWeight: 'bold' }}>
            <Input />
          </Form.Item>
        </Form>
      )}
    </Drawer>
  )
}
export default MaterialDrawer
