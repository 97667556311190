import {
  CheckOutlined,
  CheckSquareTwoTone,
  CloseOutlined,
  CloseSquareTwoTone,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { deleteMaterial, getAllMaterials } from '../../api/materials'
import Wrapper from '../../components/wrapper/Wrapper'
import MaterialDrawer from './components/MaterialDrawer'
import PdfDownloadDrawer from './components/PdfDownloadDrawer'
import { getPrivateId } from '../../utils/checker'

const Materiali = () => {
  const navigate = useNavigate()
  const [materials, setMaterials] = React.useState([])
  const [materialId, setMaterialId] = React.useState()
  const [drawerVisible, setDrawerVisible] = React.useState(false)
  const visibleRef = React.useRef(false)
  const [loading, setLoading] = React.useState(false)
  const [refetch, setRefetch] = React.useState(false)
  const [isUpdateDrawer, setIsUpdateDrawer] = React.useState(false)
  const [pdfDrawerVisible, setPdfDrawerVisible] = React.useState(false)
  const [initialModal, setInitialModal] = React.useState(true)
  const [targetMaterial, setTargetMaterial] = React.useState(undefined)

  React.useEffect(() => {
    const listener = (window.onpopstate = () => {
      if (visibleRef.current) {
        setDrawerVisible(false)
        setPdfDrawerVisible(false)
        visibleRef.current = false
      }
    })
    listener()
    setLoading(true)
    setInitialModal(true)
  }, [])

  React.useEffect(() => {
    if (targetMaterial) {
      setLoading(true)
      console.log({ userId: getPrivateId() })
      getAllMaterials({ category: targetMaterial, userId: getPrivateId() })
        .then(res => {
          setMaterials(res.data.data)
          setLoading(false)
        })
        .catch(e => {
          console.log(e)
          setLoading(false)
        })
    }
  }, [refetch])

  const remove = id => {
    deleteMaterial(id)
      .then(() => {
        setRefetch(!refetch)
        message.success('Il materiale è stato eliminato')
      })
      .catch(e => message.error(e.response.data.message))
  }

  const columns = [
    {
      title: 'Azioni',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            style={{ fontSize: 20, marginRight: 6 }}
            onClick={() => {
              setMaterialId(record._id)
              setIsUpdateDrawer(true)
              setDrawerVisible(true)
              visibleRef.current = true
              navigate('/materiali/action')
            }}
          />
          <FilePdfOutlined
            style={{ fontSize: 20, marginRight: 6 }}
            onClick={() => {
              setMaterialId(record._id)
              navigate('/materiali/action')
              visibleRef.current = true
              setPdfDrawerVisible(true)
            }}
          />
          <Popconfirm
            title="Sicuro di voler eliminare questo materiale?"
            onConfirm={() => remove(record._id)}
            okText="Si"
            placement="right"
            cancelText="No">
            <DeleteOutlined style={{ color: '#ff0000', fontSize: 20 }} />
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Categoria',
      dataIndex: 'category',
      /* filters: [
        {
          text: 'Marmo',
          value: 'marmo',
        },
        {
          text: 'Granito',
          value: 'granito',
        },
      ],
      onFilter: (value, record) => record.category.indexOf(value) === 0, */
      key: 'lastName',
    },
    {
      title: 'Codice materiale',
      dataIndex: 'materialCode',
      key: 'materialCode',
    },
    {
      title: 'Codice prestazioni',
      dataIndex: 'performanceCode',
      key: 'performanceCode',
    },
    {
      title: 'Località',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Foto',
      dataIndex: 'fileId',
      key: 'fileId',
      render: text =>
        text ? <CheckOutlined /> : <CloseSquareTwoTone twoToneColor={'red'} />,
    },
    {
      title: 'Tabella',
      dataIndex: 'details',
      key: 'details',
      render: val =>
        val?.options?.length > 0 ? (
          <CheckOutlined />
        ) : (
          <CloseSquareTwoTone twoToneColor={'red'} />
        ),
    },
  ]

  return (
    <Wrapper selectedItem={['3']}>
      <Button
        onClick={() => {
          setIsUpdateDrawer(false)
          navigate('/materiali/action')
          setDrawerVisible(true)
          visibleRef.current = true
        }}
        type="primary"
        style={{
          marginBottom: 16,
        }}>
        Aggiungi materiale
      </Button>
      <Select
        value={targetMaterial}
        placeholder="Categoria"
        onChange={val => {
          setTargetMaterial(val)
          setRefetch(!refetch)
        }}
        style={{
          width: 240,
          marginLeft: 16,
        }}>
        <Select.Option key={'granito'}>Granito</Select.Option>
        <Select.Option key={'marmo'}>Marmo</Select.Option>
      </Select>
      <Table
        dataSource={materials}
        columns={columns}
        loading={loading}
        rowKey={record => record._id}
      />
      {drawerVisible && (
        <MaterialDrawer
          update={isUpdateDrawer}
          materialId={isUpdateDrawer && materialId}
          visible={drawerVisible}
          onClose={() => {
            navigate('/materiali')
            setDrawerVisible(false)
            visibleRef.current = false
            setRefetch(!refetch)
          }}
        />
      )}
      {pdfDrawerVisible && (
        <PdfDownloadDrawer
          visible={pdfDrawerVisible}
          onClose={() => {
            navigate('/materiali')
            setPdfDrawerVisible(false)
          }}
          materialId={materialId}
        />
      )}
      <Modal
        visible={initialModal}
        closable={false}
        destroyOnClose
        onCancel={() => setInitialModal(false)}
        footer={null}>
        <Row justify="center">
          <Col>
            <Typography.Title level={4}>
              Seleziona il tipo materiale da visualizzare
            </Typography.Title>
            <Select
              value={targetMaterial}
              placeholder="Categoria"
              onChange={val => {
                setTargetMaterial(val)
                setRefetch(!refetch)
                setInitialModal(false)
              }}
              style={{
                width: 240,
              }}>
              <Select.Option key={'granito'}>Granito</Select.Option>
              <Select.Option key={'marmo'}>Marmo</Select.Option>
            </Select>
          </Col>
        </Row>
      </Modal>
    </Wrapper>
  )
}

export default Materiali
