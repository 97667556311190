import { InboxOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  List,
  message,
  Progress,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
  Upload,
} from 'antd'
import React from 'react'
import {
  createDocument,
  deleteDocumentFile,
  getDocumentInfo,
  updateDocument,
  uploadDocumentImages,
} from '../../../api/documents'
import { getMaterialsByCat } from '../../../api/materials'
import TableRow from './TableRow'
import { getPrivateId } from '../../../utils/checker'

const DocumentDrawer = ({
  visible,
  onClose,
  update,
  documentId,
  categories,
  documentCategoryId,
}) => {
  const [docData, setDocData] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [formSubmitLoading, setFormSubmitLoading] = React.useState(false)
  const [targetCategory, setTargetCategory] = React.useState('')
  const [docList, setDocList] = React.useState([])
  const [tableRows, setTableRows] = React.useState([])
  const [tableOriginal, setTableOriginal] = React.useState([])

  const uploadTracker = React.useRef()
  const [uploadTrackerState, setUploadTrackerState] = React.useState(0)

  const [showRestForm, setShowRestForm] = React.useState(update)

  const [materialsWithCat, setMaterialsWithCat] = React.useState([])

  const [form] = Form.useForm()

  const [fileList, setFileList] = React.useState([])
  React.useEffect(() => {
    uploadTracker.current = 0
    setLoading(true)
    if (update) {
      getDocumentInfo(documentId)
        .then(res => {
          setTargetCategory(res.type._id)
          if (res.dinRows && typeof res.dinRows === 'object') {
            setTableOriginal(res.dinRows)
          }
          setDocList(res.files)
          setDocData(res)
          getMaterialsByCat(res.materialId.category, getPrivateId())
            .then(res => {
              setMaterialsWithCat(res.data.data)
              setLoading(false)
            })
            .catch(e => console.log(e))
        })
        .catch(e => console.log(e))
    } else {
      setTargetCategory(documentCategoryId)

      const targetCategory = categories.find(
        obj => obj._id === documentCategoryId,
      )
      setDocData({ type: targetCategory })
      getMaterialsByCat(targetCategory.category, getPrivateId())
        .then(res => setMaterialsWithCat(res.data.data))
        .catch(e => console.log(e))
      setShowRestForm(true)
      setLoading(false)
    }
  }, [])

  const handleSubmit = val => {
    if (isPetrografia()) {
      if (update) {
        val.tableRows = [...docData?.tableRows, ...tableRows]
      } else {
        val.tableRows = tableRows
      }
    }
    if (update) {
      updateDocument(documentId, val)
        .then(() => handleFileUpload(documentId))
        .catch(e => console.log(e))
    } else {
      createDocument(val)
        .then(({ data }) => handleFileUpload(data._id))
        .catch(e => console.log(e))
    }
  }

  const handleFileUpload = id => {
    if (fileList && fileList.length > 0) {
      uploadDocumentImages(id, fileList, () => {
        uploadTracker.current += 1
        setUploadTrackerState(uploadTracker.current)
      })
        .then(() => {
          if (update) message.success('Documento modificato con successo!')
          else message.success('Documento creato con successo!')
          setFormSubmitLoading(false)
          onClose()
        })
        .catch(e => console.log(e))
    } else {
      setFormSubmitLoading(false)
      onClose()
    }
  }

  const addTableRow = () => {
    setTableRows([
      ...tableRows,
      {
        label: '',
        firstValue: '',
        secondValue: '',
      },
    ])
  }

  const handleTableChange = (index, target, value) => {
    const copy = [...JSON.parse(JSON.stringify(tableRows))]
    copy[index - tableOriginal.length][target] = value
    setTableRows(copy)
  }

  const isPetrografia = () =>
    !!(
      targetCategory === '62f694546b8fac3d4936cd01' ||
      targetCategory === '62f694776b8fac3d4936cd04'
    )

  return (
    <Drawer
      title={update ? 'Modifica documento' : 'Aggiungi documento'}
      placement="right"
      onClose={onClose}
      destroyOnClose
      width={'100%'}
      extra={
        <Space>
          <Button onClick={onClose}>Indietro</Button>
          <Button
            type="primary"
            onClick={() => {
              setFormSubmitLoading(true)
              form.submit()
            }}
            loading={formSubmitLoading}>
            Salva
          </Button>
        </Space>
      }
      visible={visible}>
      {loading ? (
        <Skeleton active />
      ) : (
        <Form
          layout="vertical"
          initialValues={docData}
          form={form}
          onFinishFailed={() => setFormSubmitLoading(false)}
          onFinish={handleSubmit}>
          <Form.Item
            label="Tipo"
            name={['type', '_id']}
            style={{ fontWeight: 'bold' }}
            rules={[{ required: true, message: 'Il tipo è obbligatorio.' }]}>
            <Select
              placeholder="Tipo"
              onChange={(_, opt) => {
                setTargetCategory(opt.value)
                form.setFieldValue('materialId', '')
                getMaterialsByCat(opt.children.split(' ')[0], getPrivateId())
                  .then(res => setMaterialsWithCat(res.data.data))
                  .catch(e => console.log(e))
                if (!update) setShowRestForm(true)
              }}>
              {categories.map(cat => {
                return (
                  <Select.Option key={cat._id}>
                    {cat.category + ' ' + cat.type}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          {showRestForm && (
            <>
              <Row justify="space-between">
                <Col span={24}>
                  <Form.Item
                    style={{ fontWeight: 'bold' }}
                    label="Materiale"
                    name={['materialId', '_id']}
                    rules={[
                      {
                        required: true,
                        message: 'Il materiale è obbligatorio.',
                      },
                    ]}>
                    <Select>
                      {materialsWithCat.map(mat => {
                        return (
                          <Select.Option key={mat._id}>
                            {mat.name}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    style={{ fontWeight: 'bold' }}
                    label="Nome Documento"
                    name="name"
                    rules={[
                      { required: true, message: 'Il nome è obbligatorio.' },
                    ]}>
                    <Input />
                  </Form.Item>
                  {formSubmitLoading && (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Progress
                        percent={parseInt(
                          (100 / fileList.length) * uploadTrackerState,
                        )}
                      />
                    </div>
                  )}
                </Col>
              </Row>
              {isPetrografia() ? (
                <>
                  <Row className="table-wrap" style={{ marginBottom: 32 }}>
                    <Col
                      span={24}
                      className="table-header"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <Typography.Title
                        level={5}
                        style={{ marginBottom: 0, marginRight: 16 }}>
                        Tabella
                      </Typography.Title>
                      <Button
                        icon={<PlusOutlined />}
                        primary
                        size="small"
                        onClick={() => addTableRow()}
                      />
                    </Col>
                    <Col span={24}>
                      {[...tableOriginal, ...tableRows]?.map((row, i) => (
                        <TableRow
                          key={i}
                          disabled={!!row._id}
                          index={i}
                          onDelete={() => {
                            if (row._id) {
                              setTableOriginal(
                                tableOriginal.filter(
                                  obj => obj._id !== row._id,
                                ),
                              )
                              setDocData(prevState => ({
                                ...prevState,
                                tableRows: prevState.tableRows.filter(
                                  obj => obj !== row._id,
                                ),
                              }))
                            } else {
                              const copy = [
                                ...JSON.parse(JSON.stringify(tableRows)),
                              ]
                              delete copy[i]
                              setTableRows(copy.filter(obj => obj !== null))
                            }
                          }}
                          {...row}
                          onChange={handleTableChange}
                        />
                      ))}
                    </Col>
                  </Row>
                </>
              ) : null}
              <Col span={24}>
                <Form.Item
                  style={{ fontWeight: 'bold' }}
                  label="Ordine"
                  name="order">
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Typography.Title level={5}>Pagine</Typography.Title>
              <Upload.Dragger
                name="images"
                multiple
                fileList={fileList}
                accept=".png,.jpg"
                onRemove={file => {
                  const index = fileList.indexOf(file)
                  const newFileList = fileList.slice()
                  newFileList.splice(index, 1)
                  setFileList(newFileList)
                }}
                beforeUpload={(_, list) => {
                  console.log([...list])

                  setFileList([...fileList, ...list])
                  return false
                }}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Clicca o trascina un immagine per caricarla.
                </p>
              </Upload.Dragger>
              {update && (
                <List
                  style={{ marginTop: 32 }}
                  size="small"
                  header={
                    <Typography.Text strong>
                      Lista immagini caricate
                    </Typography.Text>
                  }
                  bordered
                  dataSource={docList}
                  renderItem={item => (
                    <List.Item
                      key={item._id}
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}>
                      <Typography.Text>{item.name}</Typography.Text>
                      <Button
                        onClick={() => {
                          deleteDocumentFile(documentId, item._id).then(() =>
                            getDocumentInfo(documentId)
                              .then(res => {
                                setTargetCategory(res.type._id)
                                if (
                                  res.tableRows &&
                                  typeof res.tableRows === 'object'
                                ) {
                                  setTableRows(res.tableRows)
                                }
                                setDocList(res.files)
                                setDocData(res)
                                getMaterialsByCat(
                                  res.materialId.category,
                                  getPrivateId(),
                                )
                                  .then(res => {
                                    setMaterialsWithCat(res.data.data)
                                    setLoading(false)
                                  })
                                  .catch(e => console.log(e))
                              })
                              .catch(e => console.log(e)),
                          )
                        }}>
                        Rimuovi
                      </Button>
                    </List.Item>
                  )}
                />
              )}
            </>
          )}
        </Form>
      )}
    </Drawer>
  )
}

export default DocumentDrawer
