import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Row,
  Segmented,
  Select,
  Typography,
} from 'antd'
import React from 'react'
import {
  downloadDocument,
  downloadMaterial,
  downloadMaterialPost,
} from '../../../../api/documents'
import styles from './index.module.css'
import {
  FilePdfOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../redux/user'
import { getAllMaterials } from '../../../../api/materials'
import { useNavigate } from 'react-router-dom'
import PublicWrapper from '../../../../components/publicWrapper'
import IstruzioniUso from '../../../../components/istruzioniUso/IstruzioniUso'
import { publicChecker } from '../../../../utils/checker'

const DOCTYPE = 'stpo'

const { Option } = Select

const CHECKBOX_OPTIONS = [
  { value: 'oneBox', label: 'Esame Petrografico' },
  { value: 'twoBox', label: 'Resistenza alla flessione' },
  {
    value: 'threeBox',
    label: 'Resistenza alla flessione dopo 14 cili di gelo/disgelo',
  },
  {
    value: 'fourBox',
    label: 'Resistenza alla flessione dopo 56 cili di gelo/disgelo',
  },
  { value: 'fiveBox', label: 'Resistenza alla compressione' },
  {
    value: 'sixBox',
    label: 'Resistenza alla compressione dopo 56 cili di gelo/disgelo',
  },
  { value: 'sevenBox', label: "Assorbimento d' acqua a pressione atmosferica" },
  { value: 'eightBox', label: "Massa volumica apparente / Porosita' aperta" },
  { value: 'nineBox', label: "Assorbimento d' acqua per capillarita'" },
  {
    value: 'tenBox',
    label: 'Carico di rottura in corrispondenza dei fori di fissaggio',
  },
  { value: 'elevenBox', label: 'Resistenza allo shock termico' },
  {
    value: 'twelveBox',
    label: 'Resistenza allo scivolamento - finitura lucida',
  },
  {
    value: 'thirtheenBox',
    label: 'Resistenza allo scivolamento - finitura levigata',
  },
  {
    value: 'fourtheenBox',
    label: 'Resistenza allo scivolamento - finitura aggiuntiva',
  },
  { value: 'fiftheenBox', label: "Resistenza all' abrasione" },
  { value: 'sixteenBox', label: 'Isolamento termico' },
  {
    value: 'seventeenBox',
    label: 'Isolamento al suono diretto aerotrasportato',
  },
]

const STPO = () => {
  const { user } = useSelector(selectUser)
  const [activeCategory, setActiveCategory] = React.useState(null)
  const [materials, setMaterials] = React.useState([])
  const [istrUso, setIstrUso] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [lang, setLang] = React.useState('it')
  const navigate = useNavigate()

  const switchComponent = key => {
    switch (key) {
      case '1-1':
        return navigate('/public/ddp')
      case '1-2':
        return navigate('/public/ce')
      case '2-1':
        return navigate('/public/nvl')
      case '2-2':
        return navigate('/public/stpo')
      case '3':
        return navigate('/public/proveinizialitipo')
      case '4':
        return navigate('/public/petrografie')
      case '5':
        return navigate('/public/provadin')
      default:
        return navigate('/public')
    }
  }

  React.useEffect(() => {
    const typesArr = []
    if (user.categoryVisibility.includes('granite')) typesArr.push('granito')
    if (user.categoryVisibility.includes('marbre')) typesArr.push('marmo')
    setLoading(true)
    getAllMaterials({
      category: typesArr,
      ...publicChecker(),
    })
      .then(({ data }) => {
        setMaterials(data.data)
        setLoading(false)
      })
      .catch(e => {
        console.log('Errore fetch materiali', e)
      })
  }, [])

  const materialList = React.useMemo(() => {
    return materials.filter(obj => obj.category === activeCategory)
  }, [activeCategory])

  return (
    <PublicWrapper onChange={key => switchComponent(key)} loading={loading}>
      <IstruzioniUso visible={istrUso} onCancel={() => setIstrUso(false)}>
        <Typography.Text style={{ fontWeight: 'bold', color: '#797979' }}>
          Spuntare la prova o le prove i cui dati vanno resi noti e consegnati
          al committente:
        </Typography.Text>
        <br />
        <br />
        <Typography.Text style={{ fontWeight: 'bold', color: '#000' }}>
          Esame Petrografico
          <br />
          Resistenza alla flessione
          <br />
          Resistenza alla flessione dopo 14 cicli di gelo/disgelo
          <br />
          Resistenza alla flessione dopo 56 cicli di gelo/disgelo
          <br />
          Resistenza alla compressione
          <br />
          Resistenza alla compressionedopo 56 cicli di gelo/disgelo
          <br />
          Assorbimento d&apos; acqua a pressione atmosferica
          <br />
          Massa volumica apparente / Porosita&apos; aperta
          <br />
          Assorbimento d&apos; acqua per capillarita&apos;
          <br />
          Carico di rottura in corrispondenza dei fori di fissaggio
          <br />
          Resistenza allo shock termico
          <br />
          Resistenza allo scivolamento - finitura lucida
          <br />
          Resistenza allo scivolamento - finitura levigata
          <br />
          Resistenza allo scivolamento - finitura aggiuntiva
          <br />
          Resistenza all&apos; abrasione
          <br />
          Isolamento termico
          <br />
          Isolamento al suono diretto aerotrasportato
        </Typography.Text>
      </IstruzioniUso>
      <div className={styles.moduleWrapper}>
        <Typography.Title level={2}>SCHEDA TECNICA PRE-ORDINE</Typography.Title>
        <Typography.Text style={{ fontSize: 17, color: '#898989' }}>
          Questa scheda è stata predisposta per poter fornire i dati tecnici al
          committente prima ancora di formalizzare la scelta e quindi l’
          ordinazione vera e propria del materiale,
          <br /> in quanto molto spesso si rende necessario capire l’ idoneità o
          meno del materiale alla destinazione d’ uso prevista.
          <br /> La Scheda può essere composta in base alle prove richieste dal
          committente, quindi possono essere inseriti tutti i dati delle prove
          effettuate, a discrezione dell’ azienda che intende rilasciare tali
          informazioni prima che l’ ordine venga formalizzato.
          <br />
          <Typography.Text strong style={{ fontSize: 17, color: '#898989' }}>
            {' '}
            A tal proposito si raccomanda la massima attenzione, per evitare di
            fornire maggiori dati di quelli richiesti e strettamente necessari
            alla scelta del materiale da utilizzare, a tutela dell’ investimento
            economico fatto da ciascuna azienda per poter usufruire di tali
            dati.
          </Typography.Text>
        </Typography.Text>
        <Divider />
        {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Link
            style={{ fontSize: 18, fontWeight: 'bold' }}
            onClick={() => setIstrUso(true)}>
            ISTRUZIONI D&apos;USO
          </Typography.Link>
        </div>
        <Divider />
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          Selezionare la lingua desiderata
        </Typography.Title>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Select
            defaultValue="it"
            value={lang}
            style={{ width: '25%' }}
            onChange={val => setLang(val)}>
            <Option value="it">Italiano</Option>
            <Option value="en">Inglese</Option>
            <Option value="de">Tedesco</Option>
            <Option value="fr">Francese</Option>
        </Select>
        </div>
        <Divider /> */}
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          Selezionare la categoria desiderata
        </Typography.Title>
        <Segmented
          block
          options={[
            user.categoryVisibility.includes('granite') && {
              label: 'Graniti (Vedi elenco)',
              value: 'granito',
            },
            user.categoryVisibility.includes('marbre') && {
              label: 'Marmi (Vedi elenco)',
              value: 'marmo',
            },
          ].filter(obj => obj)}
          onChange={val => setActiveCategory(val)}
          value={activeCategory}
        />
        {activeCategory && (
          <Collapse
            ghost
            style={{
              paddingLeft: activeCategory === 'marmo' ? '50%' : 0,
            }}>
            {materialList &&
              materialList
                ?.sort((a, b) => parseInt(a?.order) - parseInt(b?.order))
                ?.map(currentMaterial => {
                  return (
                    <Collapse.Panel
                      key={currentMaterial._id}
                      header={
                        <Typography.Link strong style={{ fontSize: 18 }}>
                          {currentMaterial.name}
                        </Typography.Link>
                      }>
                      <Col style={{ marginLeft: 24, marginTop: -24 }}>
                        <MaterialRow
                          currentMaterial={currentMaterial}
                          activeCategory={activeCategory}
                          lang={lang}
                        />
                      </Col>
                    </Collapse.Panel>
                  )
                })}
          </Collapse>
        )}
      </div>
    </PublicWrapper>
  )
}

const MaterialRow = ({ currentMaterial, lang, activeCategory }) => {
  const [loading, setLoading] = React.useState(false)
  const [sections, setSections] = React.useState([])

  return (
    <Row span={24}>
      <Col span={24}>
        <Typography.Title level={5}>
          Spuntare la prova o le prove i cui dati vanno resi noti e consegnati
          al committente:
        </Typography.Title>
        <Checkbox.Group
          value={sections}
          style={{ width: '100%' }}
          onChange={val => setSections(val)}>
          <Row>
            {CHECKBOX_OPTIONS.map(currentCheckbox => {
              return (
                <Col
                  span={24}
                  key={currentCheckbox.value}
                  style={{ marginBottom: 4 }}>
                  <Checkbox value={currentCheckbox.value}>
                    {currentCheckbox.label}
                  </Checkbox>
                </Col>
              )
            })}
          </Row>
        </Checkbox.Group>
      </Col>
      <Col span={24}>
        <Row
          span={24}
          key={currentMaterial._id}
          className={
            activeCategory === 'marmo'
              ? styles.documentRow_marmi
              : styles.documentRow
          }
          onClick={() => {
            setLoading(true)
            downloadMaterialPost(currentMaterial._id, DOCTYPE, {
              lang,
              sections: sections.reduce((prev, obj) => {
                prev[obj] = true
                return prev
              }, {}),
            })
              .then(() => {
                setLoading(false)
              })
              .catch(() => null)
          }}>
          <div style={{ justifyContent: 'center' }}>
            <FilePdfOutlined style={{ fontSize: 24 }} />
            <Typography.Text strong style={{ marginLeft: 8, fontSize: 16 }}>
              SCHEDA TECNICA PRE-ORDINE {currentMaterial.name}
            </Typography.Text>
          </div>
          <div>
            {loading ? (
              <LoadingOutlined style={{ fontSize: 24 }} />
            ) : (
              <Button
                size="middle"
                loading={loading}
                icon={<DownloadOutlined style={{ fontSize: 20 }} />}
                type="primary"
              />
            )}
          </div>
        </Row>
      </Col>
    </Row>
  )
}

export default STPO
