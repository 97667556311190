import client from './client'
import downloadjs from 'downloadjs'
import axios from 'axios'

export const getDocumentCategories = () => {
  return new Promise((resolve, reject) => {
    client
      .get(`/documentcategories`)
      .then(({ data }) => resolve(data))
      .catch(e => reject(e))
  })
}

export const getDocumentsList = params => {
  return new Promise((resolve, reject) => {
    client
      .get(`/documents`, { params })
      .then(({ data }) => resolve(data))
      .catch(e => reject(e))
  })
}

export const getDocumentsInfo = typeId => {
  return new Promise((resolve, reject) => {
    client
      .get(`/documents`, { params: { type: typeId } })
      .then(({ data }) => resolve(data))
      .catch(e => reject(e))
  })
}

export const getDocumentInfo = docId => {
  return new Promise((resolve, reject) => {
    client
      .get(`/documents/${docId}`)
      .then(({ data }) => resolve(data))
      .catch(e => reject(e))
  })
}

export const deleteDocument = docId => {
  return new Promise((resolve, reject) => {
    client
      .delete(`/documents/${docId}`)
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}

export const createDocument = info => {
  return new Promise((resolve, reject) => {
    client
      .post(`/documents`, info)
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}

export const updateDocument = (docId, info) => {
  return new Promise((resolve, reject) => {
    client
      .put(`/documents/${docId}`, info)
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}

export const uploadDocumentImages = (docId, fileData, cb) => {
  return new Promise((resolve, reject) => {
    client
      .post('/documents/S3Url', {
        urlsNumber: fileData.length,
      })
      .then(async ({ data }) => {
        let checker = data?.map(async (currentUrl, index) => {
          return axios
            .put(currentUrl.url, fileData[index], {
              headers: {
                'Content-Type': 'image/jpeg',
              },
            })
            .then(data => {
              cb()
              return data
            })
            .catch(() => null)
        })
        checker = await Promise.all(checker)
        if (!checker.includes(null) && !checker.includes(undefined)) {
          console.log('CHECKER', checker)
          client
            .put(`/documents/${docId}`, {
              files: checker.map((currentFile, index) => ({
                name: currentFile?.config?.data?.name,
                fileId: data[index]?.key,
              })),
            })
            .then(() => resolve())
            .catch(() => new Error())
        } else {
          throw new Error()
        }
      })
      .catch(e => reject(e))
  })
}

export const downloadDocument = (docId, docName) => {
  return new Promise((resolve, reject) => {
    client
      .get(`/documents/${docId}/document`)
      .then(async res => {
        const win = window.open(res.data.url, '_blank')
        win.focus()
        resolve()
      })
      .catch(e => reject(e))
  })
}

export const downloadMaterial = (matId, docType) => {
  return new Promise((resolve, reject) => {
    client
      .get(`/pdf/${matId}/${docType}`)
      .then(async res => {
        const win = window.open(res.data.url, '_blank')
        win.focus()
        resolve()
      })
      .catch(e => reject(e))
  })
}

export const downloadMaterialPost = (matId, docType, body, params) => {
  return new Promise((resolve, reject) => {
    client
      .post(`/pdf/${matId}/${docType}`, body, { params })
      .then(async res => {
        const win = window.open(res.data.url, '_blank')
        win.focus()
        resolve()
      })
      .catch(e => reject(e))
  })
}

export const deleteDocumentFile = (docId, fileId) => {
  return new Promise((resolve, reject) => {
    client
      .delete(`/documents/${docId}/files/${fileId}`)
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}
