import { Col, Collapse, Empty, Row, Typography } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import PublicWrapper from '../../components/publicWrapper'
import './style.css'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux/user'

const Public = () => {
  const [activeComponent, setActiveComponent] = React.useState(null)
  const { user } = useSelector(selectUser)
  const navigate = useNavigate()

  const SwitchableComponents = () => {
    switch (activeComponent) {
      case '1-1':
        return navigate('/public/ddp')
      case '1-2':
        return navigate('/public/ce')
      case '2-1':
        return navigate('/public/nvl')
      case '2-2':
        return navigate('/public/stpo')
      case '3':
        return navigate('/public/proveinizialitipo')
      case '4':
        return navigate('/public/petrografie')
      case '5':
        return navigate('/public/provadin')
      default:
        return (
          <Row className="main__wrapper">
            {user?.documentVisibility === 'all' && (
              <>
                <Col span={24}>
                  <Typography.Title level={2} className="main__title">
                    CERTIFICAZIONI CE ( da allegare ai documenti di Vendita )
                  </Typography.Title>
                  <Typography.Text className="main__sub">
                    In questa sezione si possono generare e scaricare le
                    certificazioni CE composte dalla{' '}
                    <Typography.Link onClick={() => setActiveComponent('1-1')}>
                      DICHIARAZIONE DI PRESTAZIONE
                    </Typography.Link>{' '}
                    e dalla{' '}
                    <Typography.Link onClick={() => setActiveComponent('1-2')}>
                      MARCATURA CE
                    </Typography.Link>
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Title level={2} className="main__title">
                    SCHEDE TECNICHE
                  </Typography.Title>
                  <Typography.Text className="main__sub">
                    In questa sezione si possono generare e scaricare la Scheda
                    relativa alla{' '}
                    <Typography.Link onClick={() => setActiveComponent('2-1')}>
                      NORMA VOLONTARIA PER LASTRE
                    </Typography.Link>{' '}
                    e la{' '}
                    <Typography.Link onClick={() => setActiveComponent('2-2')}>
                      SCHEDA TECNICA PRE-ORDINE
                    </Typography.Link>
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Title level={2} className="main__title">
                    PROVE INIZIALI DI TIPO
                  </Typography.Title>
                  <Typography.Text className="main__sub">
                    In questa sezione si possono scaricare le{' '}
                    <Typography.Link onClick={() => setActiveComponent('3')}>
                      PROVE INIZIALI DI TIPO <br />
                    </Typography.Link>
                    I Rapporti di Prova emessi a seguito dell&apos; esecuzione
                    delle Prove Iniziali di Tipo sono di proprietà degli
                    intestatari degli stessi e, Vi ricordiamo, non vanno MAI
                    consegnati al cliente.
                    <br />
                    <Typography.Text strong className="main__sub">
                      AS.MA.VE. diffida l&apos; uso improprio dei Rapporti di
                      Prova, la manomissione dei dati, la riproduzione e la
                      cessione ad altri.
                    </Typography.Text>
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Title level={2} className="main__title">
                    PETROGRAFIE
                  </Typography.Title>
                  <Typography.Text className="main__sub">
                    In questa sezione si possono scaricare le{' '}
                    <Typography.Link onClick={() => setActiveComponent('4')}>
                      PETROGRAFIE
                    </Typography.Link>{' '}
                    sia dei materiali già testati che di quelli nuovi, non
                    ancora sottoposti a Marcatura CE, per chiarirne la natura e
                    definirne le caratteristiche geologiche.
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Title level={2} className="main__title">
                    {`PROVA SCIVOLOSITA' DIN 51130:2014`}
                  </Typography.Title>
                  <Typography.Text className="main__sub">
                    In questa sezione si possono scaricare le{' '}
                    <Typography.Link onClick={() => setActiveComponent('5')}>
                      PROVE DI SCIVOLOSITA&apos; DIN 51130:2014
                    </Typography.Link>{' '}
                    e visualizzare i risultati condivisi, cioe&apos; i risultati
                    delle prove DIN 51130 eseguiti dai partecipanti al progetto
                    CE e messi a disposizione degli altri aderenti.
                  </Typography.Text>
                </Col>
              </>
            )}
            {user?.documentVisibility === 'petrography' && (
              <Col span={24}>
                <Typography.Title level={2} className="main__title">
                  PETROGRAFIE
                </Typography.Title>
                <Typography.Text className="main__sub">
                  In questa sezione si possono scaricare le{' '}
                  <Typography.Link onClick={() => setActiveComponent('4')}>
                    PETROGRAFIE
                  </Typography.Link>{' '}
                  sia dei materiali già testati che di quelli nuovi, non ancora
                  sottoposti a Marcatura CE, per chiarirne la natura e definirne
                  le caratteristiche geologiche.
                </Typography.Text>
              </Col>
            )}
          </Row>
        )
    }
  }

  return (
    <PublicWrapper onChange={key => setActiveComponent(key)}>
      <SwitchableComponents />
    </PublicWrapper>
  )
}

export default Public
