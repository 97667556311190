import {
  Button,
  Col,
  Collapse,
  Drawer,
  Form,
  Input,
  Row,
  Skeleton,
  Space,
  Typography,
} from 'antd'
import React from 'react'
import { getMaterialInfo, updateMaterialInfo } from '../../../api/materials'

const PdfDownloadDrawer = ({ onClose, visible, materialId }) => {
  const [materialData, setMaterialData] = React.useState()
  const [formSubmitLoading, setFormSubmitLoading] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [form] = Form.useForm()
  const { Panel } = Collapse

  React.useEffect(() => {
    setLoading(true)
    getMaterialInfo(materialId)
      .then(res => {
        setMaterialData(res.data)
        setLoading(false)
      })
      .catch(e => {
        console.log(e)
        setLoading(false)
      })
  }, [])

  const handleSubmit = val => {
    console.log('SEND DETAILS', val)
    updateMaterialInfo(materialId, { details: val })
      .then(res => {
        console.log(res)
        setFormSubmitLoading(false)
      })
      .catch(e => console.log(e))
  }

  return (
    <Drawer
      title={
        loading ? <Skeleton /> : `Tabella materiale di ${materialData?.name}`
      }
      placement="right"
      onClose={onClose}
      destroyOnClose
      width={'100%'}
      extra={
        <Space>
          <Button onClick={onClose}>Indietro</Button>
          <Button
            onClick={() => {
              setFormSubmitLoading(true)
              form.submit()
            }}
            type="primary"
            loading={formSubmitLoading}>
            Salva
          </Button>
        </Space>
      }
      visible={visible}>
      {loading ? (
        <Skeleton />
      ) : (
        <Form
          layout="vertical"
          form={form}
          initialValues={materialData?.details}
          onFinishFailed={() => setFormSubmitLoading(false)}
          onFinish={handleSubmit}>
          <Row justify="space-between">
            <Col span={11}>
              <Form.Item
                label="Nome"
                name="name"
                rules={[{ required: true, message: 'Il nome è obbligatorio' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item label="Anno" name="year">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Collapse accordion>
            <Panel
              header={
                <Typography.Link className="pdf_panelHeader">
                  1. Flessione EN 12372
                </Typography.Link>
              }
              key="1"
              showArrow={false}
              forceRender>
              <Form.Item label="1. Media" name={['options', 0, 0]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="2. Deviazione standard"
                name={['options', 0, 1]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="3. Valore Minimo atteso"
                name={['options', 0, 2]}>
                <Input />
              </Form.Item>
            </Panel>
            <Panel
              header={
                <Typography.Link className="pdf_panelHeader">
                  2. Assorbimento d&apos;acqua e pressione atmosferica EN 13755
                </Typography.Link>
              }
              key="2"
              showArrow={false}
              forceRender>
              <Form.Item label="1. Media" name={['options', 1, 0]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="2. Valore Massimo atteso"
                name={['options', 1, 1]}>
                <Input />
              </Form.Item>
            </Panel>
            <Panel
              header={
                <Typography.Link className="pdf_panelHeader">
                  3. Esame Petrografico EN 12407
                </Typography.Link>
              }
              key="3"
              showArrow={false}
              forceRender>
              <Row>
                <Col span={6} style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <Form.Item label="1. Etichetta IT" name={['options', 2, 0]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <Form.Item label="2. Etichetta EN" name={['options', 2, 1]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <Form.Item label="3. Etichetta DE" name={['options', 2, 2]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <Form.Item label="4. Etichetta FR" name={['options', 2, 3]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel
              header={
                <Typography.Link className="pdf_panelHeader">
                  4. Massa Volumica e porosità aperta EN 1936
                </Typography.Link>
              }
              key="4"
              showArrow={false}
              forceRender>
              <Form.Item
                label="1. Massa volumica media"
                name={['options', 3, 0]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="2. Porosita' aperta media"
                name={['options', 3, 1]}>
                <Input />
              </Form.Item>
              <Form.Item label="3. Minimo" name={['options', 3, 2]}>
                <Input />
              </Form.Item>
              <Form.Item label="4. Massimo" name={['options', 3, 3]}>
                <Input />
              </Form.Item>
            </Panel>
            <Panel
              header={
                <Typography.Link className="pdf_panelHeader">
                  5. Carico di rottura a fori di fissaggio EN 13364
                </Typography.Link>
              }
              key="5"
              showArrow={false}
              forceRender>
              <Form.Item
                label="1. Distanza foro-rottura media"
                name={['options', 4, 0]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="2. Distanza rottura-bordo media"
                name={['options', 4, 1]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="3. carico di rottura medio"
                name={['options', 4, 2]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="4. Deviazione Standard"
                name={['options', 4, 3]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="5. Valore minimo atteso"
                name={['options', 4, 4]}>
                <Input />
              </Form.Item>
            </Panel>
            <Panel
              header={
                <Typography.Link className="pdf_panelHeader">
                  6. Pendolo EN 14231
                </Typography.Link>
              }
              key="6"
              showArrow={false}
              forceRender>
              <Typography.Title level={5}>Pendolo per interni</Typography.Title>
              <Row>
                <Col span={8} style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <Form.Item
                    label="1. Descrizione finitura" // Uso 7 perché é un campo aggiunto dopo
                    name={['options', 5, 4]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="2. Media SRV secco"
                    name={['options', 5, 0]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="3. Media SRV umido"
                    name={['options', 5, 1]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <Form.Item
                    label="4. Descrizione finitura" // Uso 7 perché é un campo aggiunto dopo
                    name={['options', 5, 5]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="5. Media SRV secco"
                    name={['options', 5, 6]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="6. Media SRV umido"
                    name={['options', 5, 7]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <Form.Item
                    label="7. Descrizione finitura" // Uso 7 perché é un campo aggiunto dopo
                    name={['options', 5, 8]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="8. Media SRV secco"
                    name={['options', 5, 9]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="9. Media SRV umido"
                    name={['options', 5, 10]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Typography.Title level={5}>Pendolo per esterni</Typography.Title>
              <Row>
                <Col span={8} style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <Form.Item
                    label="10. Descrizione finitura" // Uso 7 perché é un campo aggiunto dopo
                    name={['options', 5, 11]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="11. Media SRV secco"
                    name={['options', 5, 2]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="12. Media SRV umido"
                    name={['options', 5, 3]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <Form.Item
                    label="13. Descrizione finitura" // Uso 7 perché é un campo aggiunto dopo
                    name={['options', 5, 12]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="14. Media SRV secco"
                    name={['options', 5, 13]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="15. Media SRV umido"
                    name={['options', 5, 14]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8} style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <Form.Item
                    label="16. Descrizione finitura" // Uso 7 perché é un campo aggiunto dopo
                    name={['options', 5, 15]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="17. Media SRV secco"
                    name={['options', 5, 16]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="18. Media SRV umido"
                    name={['options', 5, 17]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel
              header={
                <Typography.Link className="pdf_panelHeader">
                  7. Abrasione EN 14157
                </Typography.Link>
              }
              key="7"
              showArrow={false}
              forceRender>
              <Form.Item label="1. Lunghezza Media" name={['options', 6, 0]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="2. Valore massimo atteso"
                name={['options', 6, 1]}>
                <Input />
              </Form.Item>
            </Panel>
            <Panel
              header={
                <Typography.Link className="pdf_panelHeader">
                  8. Gelo 14 cicli EN 12371
                </Typography.Link>
              }
              key="8"
              showArrow={false}
              forceRender>
              <Form.Item label="1. Media" name={['options', 7, 0]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="2. Deviazione standard"
                name={['options', 7, 1]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="3. Valore minimo atteso"
                name={['options', 7, 2]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="4. Variazione % dopo 14 cicli"
                name={['options', 7, 3]}>
                <Input />
              </Form.Item>
            </Panel>
            <Panel
              header={
                <Typography.Link className="pdf_panelHeader">
                  9. Gelo 56 cicli EN 12371
                </Typography.Link>
              }
              key="9"
              showArrow={false}
              forceRender>
              <Form.Item label="1. Media" name={['options', 8, 0]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="2. Deviazione standard"
                name={['options', 8, 1]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="3. Valore minimo atteso"
                name={['options', 8, 2]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="4. Variazione % dopo 56 cicli"
                name={['options', 8, 3]}>
                <Input />
              </Form.Item>
            </Panel>
            <Panel
              header={
                <Typography.Link className="pdf_panelHeader">
                  10. Shock termici EN 14066
                </Typography.Link>
              }
              key="10"
              showArrow={false}
              forceRender>
              <Form.Item label="1. Media" name={['options', 9, 0]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="2. Deviazione standard"
                name={['options', 9, 1]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="3. Valore minimo atteso"
                name={['options', 9, 2]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="4. Variazione % dopo 20 cicli"
                name={['options', 9, 3]}>
                <Input />
              </Form.Item>
              <Form.Item label="5. Variazione % massa" name={['options', 9, 4]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="6. Variazione % porosita' aperta"
                name={['options', 9, 5]}>
                <Input />
              </Form.Item>
            </Panel>
            <Panel
              header={
                <Typography.Link className="pdf_panelHeader">
                  11. Assorbimento d&apos;acqua per capillarita&apos; EN 1925
                </Typography.Link>
              }
              key="11"
              showArrow={false}
              forceRender>
              <Form.Item label="1. Media" name={['options', 10, 0]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="2. Valore Massimo Atteso"
                name={['options', 10, 1]}>
                <Input />
              </Form.Item>
            </Panel>
            <Panel
              header={
                <Typography.Link className="pdf_panelHeader">
                  12. Compressione EN 1926
                </Typography.Link>
              }
              key="12"
              showArrow={false}
              forceRender>
              <Form.Item label="1. Media" name={['options', 11, 0]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="2. Deviazione Standard"
                name={['options', 11, 1]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="3. Valore Minimo Atteso"
                name={['options', 11, 2]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="4. Coefficiente di Variazione"
                name={['options', 11, 3]}>
                <Input />
              </Form.Item>
            </Panel>
            <Panel
              header={
                <Typography.Link className="pdf_panelHeader">
                  13. Gelo 56 cicli + compressione EN 12371
                </Typography.Link>
              }
              key="13"
              showArrow={false}
              forceRender>
              <Form.Item label="1. Media" name={['options', 12, 0]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="2. Deviazione standard"
                name={['options', 12, 1]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="3. Valore minimo atteso"
                name={['options', 12, 2]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="4. Coefficiente di Variazione"
                name={['options', 12, 3]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="5. Variazione % dopo 56 cicli"
                name={['options', 12, 4]}>
                <Input />
              </Form.Item>
            </Panel>
          </Collapse>
        </Form>
      )}
    </Drawer>
  )
}

export default PdfDownloadDrawer
