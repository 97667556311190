import axios from 'axios'
import { store } from '../redux/store'
import { logout, selectToken } from '../redux/user'

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

client.interceptors.request.use(request => {
  request.headers.authorization = `Bearer ${selectToken(store.getState())}`
  return request
})

client.interceptors.response.use(
  response => response,
  error => {
    console.log(error)
    if (error.response.status === 401) {
      store.dispatch(logout())
      window.location.href = `/`
    }
    return Promise.reject(error)
  },
)

export default client
