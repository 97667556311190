import { Button, Col, Form, Input, message, Row } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { login } from '../../api/auth'
import { Logo } from '../../assets'
import { store } from '../../redux/store'
import {
  selectToken,
  selectUser,
  setUserDetails,
  setUserToken,
} from '../../redux/user'
import styles from './index.module.css'

function Login() {
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)

  const onFinish = values => {
    setLoading(true)
    login(values)
      .then(data => {
        store.dispatch(setUserToken(data.jwtToken))
        store.dispatch(setUserDetails(data.data))
        setLoading(false)
      })
      .catch(e => {
        message.error(e.response.data.message)
        setLoading(false)
      })
  }

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Row className={styles.bg} justify="center" align="middle">
      <Col span={6}>
        <Row justify="center">
          <Col span={24} className={styles.center}>
            <img className={styles.logo} src={Logo} />
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col className={styles.center} span={24}>
            <Form
              id={styles.loginform}
              name="login-form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical">
              <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: "Inserisci l'username!" }]}>
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Inserisci la password!' }]}>
                <Input.Password />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}>
                  Accedi
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Login

/*
<div className={styles.bg}>
        <Row>
          <Col span={8}>
            <img className={styles.logo} src={Logo} />
          </Col>
        </Row>
        <Row>

        </Row>
      </div>

*/
