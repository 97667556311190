import React from 'react'
import Wrapper from '../../components/wrapper/Wrapper'

import './index.css'
import Logo from '../../assets/images/logo.png'
import { Typography } from 'antd'

const Home = () => {
  return (
    <Wrapper selectedItem={['0']}>
      <div>
        <Typography.Title style={{ fontWeight: 'bold', fontSize: 22 }}>
          Pannello amministrativo
        </Typography.Title>
        <Typography.Text style={{ fontSize: 16 }}>
          Navigando nel menú laterale troverai una varietà di sezioni che ti
          consentiranno di gestire i tuoi contenuti.
        </Typography.Text>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <img src={Logo} width={512} height={'auto'} style={{ opacity: 0.25 }} />
      </div>
    </Wrapper>
  )
}

export default Home
