import {
  Button,
  Col,
  Collapse,
  Divider,
  Row,
  Segmented,
  Select,
  Typography,
} from 'antd'
import React from 'react'
import { downloadMaterial } from '../../../../api/documents'
import styles from './index.module.css'
import { FolderViewOutlined, RightOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../redux/user'
import { getAllMaterials } from '../../../../api/materials'
import CEModal from './components/modal'
import { useNavigate } from 'react-router-dom'
import PublicWrapper from '../../../../components/publicWrapper'
import IstruzioniUso from '../../../../components/istruzioniUso/IstruzioniUso'
import PhotoModal from './components/PhotoModal'
import { publicChecker } from '../../../../utils/checker'

const DOCTYPE = 'ce'
const { Option } = Select

const MCE = () => {
  const { user } = useSelector(selectUser)
  const [activeCategory, setActiveCategory] = React.useState(null)
  const [materials, setMaterials] = React.useState([])
  const [istrUso, setIstrUso] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [lang, setLang] = React.useState('it')
  const [CEModalConf, setCEModalConf] = React.useState({
    visible: false,
  })
  const [photoModalConf, setPhotoModalConf] = React.useState({
    visible: false,
  })
  const navigate = useNavigate()

  const switchComponent = key => {
    switch (key) {
      case '1-1':
        return navigate('/public/ddp')
      case '1-2':
        return navigate('/public/ce')
      case '2-1':
        return navigate('/public/nvl')
      case '2-2':
        return navigate('/public/stpo')
      case '3':
        return navigate('/public/proveinizialitipo')
      case '4':
        return navigate('/public/petrografie')
      case '5':
        return navigate('/public/provadin')
      default:
        return navigate('/public')
    }
  }

  React.useEffect(() => {
    const typesArr = []
    if (user.categoryVisibility.includes('granite')) typesArr.push('granito')
    if (user.categoryVisibility.includes('marbre')) typesArr.push('marmo')
    setLoading(true)
    getAllMaterials({
      category: typesArr,
      ...publicChecker(),
    })
      .then(({ data }) => {
        setMaterials(data.data)
        setLoading(false)
      })
      .catch(e => {
        console.log('Errore fetch materiali', e)
      })
  }, [])

  const materialList = React.useMemo(() => {
    return materials.filter(obj => obj.category === activeCategory)
  }, [activeCategory])
  return (
    <PublicWrapper onChange={key => switchComponent(key)} loading={loading}>
      <IstruzioniUso visible={istrUso} onCancel={() => setIstrUso(false)}>
        <Typography.Text style={{ color: '#797979' }}>
          Le schede della Marcatura CE sono state realizzate raggruppando le
          Norme di prodotto in 3 categorie:
          <br /> - per ESTERNI
          <br />- per INTERNI
          <br />- per PAVIMENTAZIONI STRADALI.
          <br />
          <br />
          La Marcatura CE per ESTERNI comprende i dati previsti dalle seguenti
          Norme di Prodotto:
          <br /> EN 12057 Marmette per pavimentazioni e scale esterne <br />
          EN 12057 Marmette per rivestimenti esterni <br />
          EN 12058 Lastre per pavimentazioni e scale esterne <br />
          EN 1469 Lastre per rivestimenti esterni
          <br />
          <br /> La Marcatura CE per INTERNI comprende i dati previsti dalle
          seguenti Norme di Prodotto:
          <br /> EN 12057 Marmette per pavimentazioni e scale interne <br />
          EN 12057 Marmette per rivestimenti interni <br />
          EN 12058 Lastre per pavimentazioni e scale interne <br />
          EN 1469 Lastre per rivestimenti interni <br />
          <br />
          La Marcatura CE per PAVIMENTAZIONI STRADALI comprende i dati previsti
          dalle seguenti Norme di Prodotto:
          <br />
          EN 1341 Lastre per pavimentazioni esterne («stradali»: piazze,
          marciapiedi, strade) <br />
          EN 1342 Cubetti per pavimentazioni esterne («stradali»: piazze,
          marciapiedi, strade) <br />
          EN 1343 Cordoli per pavimentazioni esterne («stradali»: piazze,
          marciapiedi, strade)
        </Typography.Text>
        <br />
        <br />
        <Typography.Text style={{ fontWeight: 'bold', color: '#797979' }}>
          Spuntare la Norma di Riferimento corrispondete al prodotto per il
          quale si vuole generare la Marcatura CE.
        </Typography.Text>
      </IstruzioniUso>
      <div className={styles.moduleWrapper}>
        <PhotoModal
          {...photoModalConf}
          onClose={() => setPhotoModalConf({ visible: false })}
        />
        <CEModal
          {...CEModalConf}
          onClose={() =>
            setCEModalConf({
              visible: false,
            })
          }
          activeCategory={activeCategory}
        />
        <Typography.Title level={2}>MARCATURA CE</Typography.Title>
        <Typography.Text style={{ fontSize: 17, color: '#898989' }}>
          La Marcatura CE è quel documento che va apposto sui prodotti da
          costruzione immessi sul mercato dell’ Unione Europea per i quali il
          fabbricante ha redatto una Dichiarazione di Prestazione.
          <br /> Se la DoP non è stata redatta dal fabbricante, la Marcatura CE
          non viene apposta.
          <br /> La Marcatura CE è l’ unica marcatura che attesta la conformità
          del prodotto da costruzione alla prestazione dichiarata in relazione
          alle caratteristiche essenziali.
          <br /> La Marcatura CE va apposta in modo visibile, leggibile e
          indelebile sul prodotto da costruzione o su un’ etichetta ad esso
          applicata.
          <br /> Se ciò fosse impossibile o ingiustificato a causa della natura
          del prodotto, essa è apposta sull’ imballaggio o sui documenti di
          accompagnamento.
          <br /> Considerando che per i prodotti di pietra naturale gli
          imballaggi vengono eliminati, si raccomanda di apporre la Marcatura CE
          sui documenti di accompagnamento, come DDT, fatture, ecc. Per maggiore
          sicurezza Vi suggeriamo di indicare in calce al DDT, una dicitura del
          tipo:
          <br />{' '}
          <Typography.Text strong style={{ fontSize: 17, color: '#898989' }}>
            ”La documentazione relativa alla Marcatura CE verrà fornita al
            momento dell’ emissione della fattura di vendita”
          </Typography.Text>{' '}
          oppure{' '}
          <Typography.Text strong style={{ fontSize: 17, color: '#898989' }}>
            “Seguirà documentazione per Marcatura CE in allegato alla fattura”
          </Typography.Text>
          , ed inviare le certificazioni mediante PEC, in modo da poter
          dimostrate l’ effettiva emissione della Marcatura CE e della DoP.
          <br /> La Marcatura CE è fornita nella lingua o nelle lingue richieste
          dallo Stato in cui il prodotto è messo a disposizione.
        </Typography.Text>
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Link
            style={{ fontSize: 18, fontWeight: 'bold' }}
            onClick={() => setIstrUso(true)}>
            ISTRUZIONI D&apos;USO
          </Typography.Link>
        </div>
        <Divider />
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          Selezionare la lingua desiderata
        </Typography.Title>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Select
            defaultValue="it"
            value={lang}
            style={{ width: '25%' }}
            onChange={val => setLang(val)}>
            <Option value="it">Italiano</Option>
            <Option value="en">Inglese</Option>
            <Option value="de">Tedesco</Option>
            <Option value="fr">Francese</Option>
          </Select>
        </div>

        <Divider />
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          Selezionare la categoria desiderata
        </Typography.Title>
        <Segmented
          block
          options={[
            user.categoryVisibility.includes('granite') && {
              label: 'Graniti (Vedi elenco)',
              value: 'granito',
            },
            user.categoryVisibility.includes('marbre') && {
              label: 'Marmi (Vedi elenco)',
              value: 'marmo',
            },
          ].filter(obj => obj)}
          onChange={val => setActiveCategory(val)}
          value={activeCategory}
        />
        {activeCategory && (
          <Collapse
            ghost
            style={{
              paddingLeft: activeCategory === 'marmo' ? '50%' : 0,
            }}>
            {materialList &&
              materialList
                ?.sort((a, b) => parseInt(a?.order) - parseInt(b?.order))
                ?.map(currentMaterial => {
                  return (
                    <Collapse.Panel
                      key={currentMaterial._id}
                      header={
                        <Typography.Link strong style={{ fontSize: 18 }}>
                          {currentMaterial.name}
                        </Typography.Link>
                      }>
                      <Col style={{ marginLeft: 24, marginTop: -24 }}>
                        <TypeRow
                          activeCategory={activeCategory}
                          label={`FOTOGRAFIA`}
                          onClick={() =>
                            setPhotoModalConf({
                              visible: true,
                              material: currentMaterial,
                            })
                          }
                        />
                        <TypeRow
                          activeCategory={activeCategory}
                          label={`CE - Esterni - ${lang.toUpperCase()}`}
                          onClick={() =>
                            setCEModalConf({
                              visible: true,
                              type: 'est',
                              lang,
                              material: currentMaterial,
                            })
                          }
                        />
                        <TypeRow
                          activeCategory={activeCategory}
                          label={`CE - Interni - ${lang.toUpperCase()}`}
                          onClick={() =>
                            setCEModalConf({
                              visible: true,
                              type: 'int',
                              lang,
                              material: currentMaterial,
                            })
                          }
                        />
                        <TypeRow
                          activeCategory={activeCategory}
                          label={`CE - Pavimentazioni stradali - ${lang.toUpperCase()}`}
                          onClick={() =>
                            setCEModalConf({
                              visible: true,
                              type: 'pav',
                              lang,
                              material: currentMaterial,
                            })
                          }
                        />
                      </Col>
                    </Collapse.Panel>
                  )
                })}
          </Collapse>
        )}
      </div>
    </PublicWrapper>
  )
}

const TypeRow = ({ label, onClick, activeCategory }) => {
  return (
    <Row
      span={24}
      className={
        activeCategory === 'marmo'
          ? styles.documentRow_marmi
          : styles.documentRow
      }>
      <div style={{ justifyContent: 'center' }}>
        <FolderViewOutlined style={{ fontSize: 24 }} />
        <Typography.Text strong style={{ marginLeft: 8, fontSize: 16 }}>
          {label}
        </Typography.Text>
      </div>
      <div>
        <Button
          size="middle"
          icon={<RightOutlined style={{ fontSize: 20 }} />}
          type="primary"
          onClick={onClick}
        />
      </div>
    </Row>
  )
}

export default MCE
