import {
  CheckOutlined,
  CloseSquareTwoTone,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Typography,
} from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  deleteDocument,
  getDocumentCategories,
  getDocumentsList,
} from '../../api/documents'
import Wrapper from '../../components/wrapper/Wrapper'
import DocumentDrawer from './components/DocumentDrawer'
import { getPrivateId } from '../../utils/checker'

const Documenti = () => {
  const navigate = useNavigate()
  const [documentData, setDocumentData] = React.useState([])
  const [documentCategories, setDocumentCategories] = React.useState()

  const [documentCategoryId, setDocumentCategoryId] = React.useState('')
  const [documentId, setDocumentId] = React.useState()

  const [loading, setLoading] = React.useState(false)
  const [initialModal, setInitialModal] = React.useState(true)

  const [drawerVisible, setDrawerVisible] = React.useState(false)
  const visibleRef = React.useRef(false)
  const [refetch, setRefetch] = React.useState(false)
  const [isUpdateDrawer, setIsUpdateDrawer] = React.useState(false)

  const { Option } = Select

  React.useEffect(() => {
    const listener = (window.onpopstate = () => {
      if (visibleRef.current) {
        setDrawerVisible(false)
        visibleRef.current = false
      }
    })
    listener()
    setLoading(true)
    getDocumentCategories()
      .then(res => {
        setDocumentCategories(res.data)
        setLoading(false)
      })
      .catch(e => console.log(e))
  }, [])

  React.useEffect(() => {
    setLoading(true)
    getDocumentsList({ type: documentCategoryId, userId: getPrivateId() })
      .then(res => {
        setDocumentData(res.data)
        setLoading(false)
      })
      .catch(e => {
        console.log(e)
        setLoading(false)
      })
  }, [refetch])

  const remove = id => {
    deleteDocument(id)
      .then(() => {
        setRefetch(!refetch)
        message.success('Il documento è stato eliminato')
      })
      .catch(e => console.log(e))
  }

  const columns = [
    {
      title: 'Azioni',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            style={{ fontSize: 20, marginRight: 6 }}
            onClick={() => {
              setDocumentId(record._id)
              setIsUpdateDrawer(true)
              navigate('/documenti/action')
              setDrawerVisible(true)
              visibleRef.current = true
            }}
          />
          <Popconfirm
            title="Sicuro di voler eliminare questo documento?"
            onConfirm={() => remove(record._id)}
            okText="Si"
            placement="right"
            cancelText="No">
            <DeleteOutlined style={{ color: '#ff0000', fontSize: 20 }} />
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: 'Materiale',
      dataIndex: ['materialId', 'name'],
      key: 'material',
    },
    {
      title: 'Nome documento',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tipo',
      dataIndex: ['type'],
      key: 'type',
      render: record => `${record.category} - ${record.type}`,
    },
    {
      title: 'Foto',
      dataIndex: 'files',
      key: 'files',
      render: val =>
        val?.length > 0 ? (
          <CheckOutlined />
        ) : (
          <CloseSquareTwoTone twoToneColor={'red'} />
        ),
    },
    {
      title: 'Ordine',
      dataIndex: 'order',
      key: 'order',
    },
  ]

  return (
    <Wrapper selectedItem={['4']}>
      <Row>
        <Col>
          <Button
            onClick={() => {
              setIsUpdateDrawer(false)
              navigate('/documenti/action')
              setDrawerVisible(true)
              visibleRef.current = true
            }}
            type="primary"
            style={{
              marginBottom: 16,
            }}>
            Aggiungi documento
          </Button>
        </Col>
        <Col offset={1}>
          <Select
            value={documentCategoryId}
            placeholder="Categoria"
            onChange={val => {
              setDocumentCategoryId(val)
              setRefetch(!refetch)
            }}
            style={{
              width: 240,
            }}>
            {documentCategories?.map(cat => {
              return (
                <Option key={cat._id}>{cat.category + ' ' + cat.type}</Option>
              )
            })}
          </Select>
        </Col>
      </Row>

      <Table
        dataSource={documentData}
        columns={columns}
        loading={loading}
        pagination={{
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true,
        }}
        rowKey={record => record._id}
      />

      {drawerVisible && (
        <DocumentDrawer
          categories={documentCategories}
          documentId={documentId}
          documentCategoryId={documentCategoryId}
          update={isUpdateDrawer}
          visible={drawerVisible}
          onClose={() => {
            navigate('/documenti')
            setDrawerVisible(false)
            visibleRef.current = false
            setRefetch(!refetch)
          }}
        />
      )}

      <Modal
        visible={initialModal}
        closable={false}
        destroyOnClose
        onCancel={() => setInitialModal(false)}
        footer={null}>
        {loading ? (
          <Skeleton />
        ) : (
          <Row justify="center">
            <Col>
              <Typography.Title level={4}>
                Seleziona il tipo di documento
              </Typography.Title>
              <Select
                placeholder="Categoria"
                onChange={val => {
                  setInitialModal(false)
                  setDocumentCategoryId(val)
                  setRefetch(!refetch)
                }}
                style={{
                  width: 240,
                }}>
                {documentCategories?.map(cat => {
                  return (
                    <Option key={cat._id}>
                      {cat.category + ' ' + cat.type}
                    </Option>
                  )
                })}
              </Select>
            </Col>
          </Row>
        )}
      </Modal>
    </Wrapper>
  )
}

export default Documenti
