import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Badge, Button, Popconfirm, Space, Table } from 'antd'
import React from 'react'
import { deleteUser, getUserList } from '../../api/users'
import Wrapper from '../../components/wrapper/Wrapper'
import UserDrawer from './components/UserDrawer'

import './index.css'
import { useNavigate } from 'react-router-dom'

const Aziende = () => {
  const navigate = useNavigate()
  const [users, setUsers] = React.useState([])
  const [userId, setUserId] = React.useState()
  const [refetch, setRefetch] = React.useState(false)
  const [drawerVisible, setDrawerVisible] = React.useState(false)
  const visibleRef = React.useRef(false)
  const [loading, setLoading] = React.useState(false)
  const [isUpdateDrawer, setIsUpdateDrawer] = React.useState(false)

  React.useEffect(() => {
    const listener = (window.onpopstate = () => {
      if (visibleRef.current) {
        setDrawerVisible(false)
        visibleRef.current = false
      }
    })
    listener()
    setLoading(true)
    getUserList()
      .then(res => {
        setUsers(res.data)
        setLoading(false)
      })
      .catch(e => {
        console.log(e)
        setLoading(false)
      })
  }, [refetch])

  const remove = id => {
    deleteUser(id)
      .then(() => setRefetch(!refetch))
      .catch(e => console.log(e))
  }

  const columns = [
    {
      title: 'Azioni',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            style={{ fontSize: 20, marginRight: 6 }}
            onClick={() => {
              setIsUpdateDrawer(true)
              setUserId(record._id)
              navigate('/aziende/action')
              setDrawerVisible(true)
              visibleRef.current = true
            }}
          />
          <Popconfirm
            title="Sicuro di voler eliminare questo utente?"
            onConfirm={() => remove(record._id)}
            okText="Si"
            placement="right"
            cancelText="No">
            <DeleteOutlined style={{ color: '#ff0000', fontSize: 20 }} />
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: 'Nome azienda',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'Nome',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Cognome',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Stato',
      dataIndex: 'userStatus',
      key: 'userStatus',
      render: status => {
        return <Badge status={status ? 'success' : 'error'} />
      },
    },
  ]

  return (
    <Wrapper selectedItem={['2']}>
      <Button
        onClick={() => {
          setIsUpdateDrawer(false)
          navigate('/aziende/action')
          setDrawerVisible(true)
          visibleRef.current = true
        }}
        type="primary"
        style={{
          marginBottom: 16,
        }}>
        Aggiungi utente
      </Button>
      <Table
        dataSource={users}
        columns={columns}
        loading={loading}
        rowKey={record => record._id}
      />
      {drawerVisible && (
        <UserDrawer
          update={isUpdateDrawer}
          visible={drawerVisible}
          onClose={() => {
            setDrawerVisible(false)
            navigate('/aziende')
            visibleRef.current = false
            setRefetch(!refetch)
          }}
          userId={isUpdateDrawer && userId}
        />
      )}
    </Wrapper>
  )
}

export default Aziende
