import { DeleteOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Typography } from 'antd'
import React from 'react'

const rowClass = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px 16px 4px 0px',
}

const TableRow = ({
  label,
  firstValue,
  secondValue,
  onChange,
  index,
  onDelete,
  disabled = false,
}) => {
  return (
    <Row style={{ borderBottom: '1px solid #fff' }}>
      <Col span={10} className="table-row" style={rowClass}>
        <Input
          disabled={disabled}
          placeholder="Finitura"
          value={label}
          onChange={({ target }) => onChange(index, 'label', target.value)}
        />
      </Col>
      <Col span={6} className="table-row" style={rowClass}>
        <Input
          disabled={disabled}
          placeholder="Angolo di scivolamento medio"
          value={firstValue}
          onChange={({ target }) => onChange(index, 'firstValue', target.value)}
        />
      </Col>
      <Col
        span={6}
        className="table-row"
        style={{ ...rowClass, borderRightColor: 'white' }}>
        <Input
          disabled={disabled}
          placeholder="Classe di scivolamento"
          value={secondValue}
          onChange={({ target }) =>
            onChange(index, 'secondValue', target.value)
          }
        />
      </Col>
      <Col
        span={2}
        className="table-row"
        style={{ ...rowClass, borderRightColor: 'white', padding: '4px 0px' }}>
        <Button icon={<DeleteOutlined />} onClick={() => onDelete(index)} />
      </Col>
    </Row>
  )
}

export default TableRow
