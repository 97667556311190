import {
  Button,
  Col,
  Collapse,
  Divider,
  Row,
  Segmented,
  Typography,
} from 'antd'
import React from 'react'
import { downloadDocument, getDocumentsList } from '../../../../api/documents'
import styles from './index.module.css'
import {
  FilePdfOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../redux/user'
import { useNavigate } from 'react-router-dom'
import PublicWrapper from '../../../../components/publicWrapper'
import { publicChecker } from '../../../../utils/checker'

const Petrografie = () => {
  const { user } = useSelector(selectUser)
  const [activeCategory, setActiveCategory] = React.useState(null)
  const [materials, setMaterials] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const navigate = useNavigate()

  const switchComponent = key => {
    switch (key) {
      case '1-1':
        return navigate('/public/ddp')
      case '1-2':
        return navigate('/public/ce')
      case '2-1':
        return navigate('/public/nvl')
      case '2-2':
        return navigate('/public/stpo')
      case '3':
        return navigate('/public/proveinizialitipo')
      case '4':
        return navigate('/public/petrografie')
      case '5':
        return navigate('/public/provadin')
      default:
        return navigate('/public')
    }
  }

  React.useEffect(() => {
    const typesArr = []
    if (user.categoryVisibility.includes('granite'))
      typesArr.push('62f6947e6b8fac3d4936cd05')
    if (user.categoryVisibility.includes('marbre'))
      typesArr.push('62f6945b6b8fac3d4936cd02')
    setLoading(true)
    getDocumentsList({
      type: typesArr,
      ...publicChecker(),
    })
      .then(({ data }) => {
        setMaterials(
          data.reduce((group, product) => {
            const category = product.materialId.category
            group[category] = group[category] ?? []
            group[category].push(product)
            return group
          }, {}),
          setLoading(false),
        )
      })
      .catch(e => {
        console.log('Errore fetch materiali', e)
      })
  }, [])

  const materialList = React.useMemo(() => {
    return materials[activeCategory]?.reduce((group, product) => {
      const category = product.materialId.name
      group[category] = group[category] ?? []
      group[category].push(product)
      return group
    }, {})
  }, [activeCategory])

  return (
    <PublicWrapper onChange={key => switchComponent(key)} loading={loading}>
      <div className={styles.moduleWrapper}>
        <Typography.Title level={2}>PETROGRAFIE</Typography.Title>
        <Typography.Text style={{ fontSize: 17, color: '#898989' }}>
          La Nota Accompagnatoria ricavata dall&apos; Esame Petrografico
          evidenzia le caratteristiche cromatiche e la scala di durezza Mohs,
          con indicazione del nome commerciale del materiale, la definizione
          petrografica e la categoria merceologica.
        </Typography.Text>
        <Divider />
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          Selezionare la categoria desiderata
        </Typography.Title>
        <Segmented
          block
          options={[
            user.categoryVisibility.includes('granite') && {
              label: 'Graniti (Vedi elenco)',
              value: 'granito',
            },
            user.categoryVisibility.includes('marbre') && {
              label: 'Marmi (Vedi elenco)',
              value: 'marmo',
            },
          ].filter(obj => obj)}
          onChange={val => setActiveCategory(val)}
          value={activeCategory}
        />
        {activeCategory && (
          <Collapse
            ghost
            style={{
              paddingLeft: activeCategory === 'marmo' ? '50%' : 0,
            }}>
            {materialList &&
              Object.keys(materialList)?.map(currentMaterial => {
                return (
                  <Collapse.Panel
                    header={
                      <Typography.Link strong style={{ fontSize: 18 }}>
                        {currentMaterial}
                      </Typography.Link>
                    }
                    key={currentMaterial}>
                    <Col style={{ marginLeft: 24, marginTop: -24 }}>
                      {materialList[currentMaterial]
                        ?.sort(
                          (a, b) => parseInt(a?.order) - parseInt(b?.order),
                        )
                        ?.map(currentDocument => {
                          return (
                            <DocumentRow
                              activeCategory={activeCategory}
                              currentDocument={currentDocument}
                              key={currentDocument._id}
                            />
                          )
                        })}
                    </Col>
                  </Collapse.Panel>
                )
              })}
          </Collapse>
        )}
      </div>
    </PublicWrapper>
  )
}

const DocumentRow = ({ currentDocument, activeCategory }) => {
  const [loading, setLoading] = React.useState(false)

  return (
    <Row
      span={24}
      key={currentDocument._id}
      className={
        activeCategory === 'marmo'
          ? styles.documentRow_marmi
          : styles.documentRow
      }
      onClick={() => {
        setLoading(true)
        downloadDocument(currentDocument._id, currentDocument.name)
          .then(() => {
            setLoading(false)
          })
          .catch(() => null)
      }}>
      <div style={{ justifyContent: 'center' }}>
        <FilePdfOutlined style={{ fontSize: 24 }} />
        <Typography.Text strong style={{ marginLeft: 8, fontSize: 16 }}>
          {currentDocument.name}
        </Typography.Text>
      </div>
      <div>
        {loading ? (
          <LoadingOutlined style={{ fontSize: 24 }} />
        ) : (
          <Button
            size="middle"
            loading={loading}
            icon={<DownloadOutlined style={{ fontSize: 20 }} />}
            type="primary"
          />
        )}
      </div>
    </Row>
  )
}

export default Petrografie
