import axios from 'axios'
import client from './client'

export const createMaterial = info => {
  return new Promise((resolve, reject) => {
    client
      .post(`/materials`, info)
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}

export const deleteMaterial = id => {
  return new Promise((resolve, reject) => {
    client
      .delete(`/materials/${id}`)
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}

export const getAllMaterials = params => {
  return new Promise((resolve, reject) => {
    client
      .get(`/materials`, { params })
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}

export const getAllDinRows = id => {
  return new Promise((resolve, reject) => {
    client
      .get(`/materials/${id}/dinTables`)
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}

export const getMaterialInfo = materialId => {
  return new Promise((resolve, reject) => {
    client
      .get(`/materials/${materialId}`)
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}

export const getMaterialImage = materialId => {
  return new Promise((resolve, reject) => {
    client
      .get(`/materials/${materialId}/document`)
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}

export const updateMaterialInfo = (materialId, info) => {
  return new Promise((resolve, reject) => {
    client
      .put(`/materials/${materialId}`, info)
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}

export const uploadMaterialImage = (materialId, image) => {
  return new Promise((resolve, reject) => {
    client.get('/materials/S3Url').then(async ({ data }) => {
      axios
        .put(data.url, image[0], {
          headers: {
            'Content-Type': 'image/jpeg',
          },
        })
        .then(() => {
          client
            .put(`/materials/${materialId}`, {
              fileId: data.key,
            })
            .then(() => resolve())
            .catch(() => new Error())
        })
        .catch(e => reject(e))
    })
  })
}

export const getMaterialsByCat = (cat, userId) => {
  return new Promise((resolve, reject) => {
    client
      .get(`/materials`, { params: { category: cat, userId } })
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}
