import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: [],
  token: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, { payload }) => {
      state.user = payload
    },
    setUserToken: (state, { payload }) => {
      state.token = payload
    },
    logout: state => {
      state.user = []
      state.token = null
    },
  },
  extraReducers: {},
})

export const { setUserDetails, setUserToken, logout } = userSlice.actions

export const selectUser = state => state.user /* user state selector */

export const selectToken = state => state.user.token

export default userSlice.reducer
