import {
  Button,
  Col,
  Collapse,
  Divider,
  Row,
  Segmented,
  Typography,
} from 'antd'
import React from 'react'
import { downloadDocument, downloadMaterial } from '../../../../api/documents'
import styles from './index.module.css'
import {
  FilePdfOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../redux/user'
import { getAllMaterials } from '../../../../api/materials'
import { useNavigate } from 'react-router-dom'
import PublicWrapper from '../../../../components/publicWrapper'
import { publicChecker } from '../../../../utils/checker'

const DOCTYPE = 'nvpl'

const NVL = () => {
  const { user } = useSelector(selectUser)
  const [activeCategory, setActiveCategory] = React.useState(null)
  const [materials, setMaterials] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const navigate = useNavigate()

  const switchComponent = key => {
    switch (key) {
      case '1-1':
        return navigate('/public/ddp')
      case '1-2':
        return navigate('/public/ce')
      case '2-1':
        return navigate('/public/nvl')
      case '2-2':
        return navigate('/public/stpo')
      case '3':
        return navigate('/public/proveinizialitipo')
      case '4':
        return navigate('/public/petrografie')
      case '5':
        return navigate('/public/provadin')
      default:
        return navigate('/public')
    }
  }

  React.useEffect(() => {
    const typesArr = []
    if (user.categoryVisibility.includes('granite')) typesArr.push('granito')
    if (user.categoryVisibility.includes('marbre')) typesArr.push('marmo')
    setLoading(true)
    getAllMaterials({
      category: typesArr,
      ...publicChecker(),
    })
      .then(({ data }) => {
        setMaterials(data.data)
        setLoading(false)
      })
      .catch(e => {
        console.log('Errore fetch materiali', e)
      })
  }, [])

  const materialList = React.useMemo(() => {
    return materials.filter(obj => obj.category === activeCategory)
  }, [activeCategory])

  return (
    <PublicWrapper onChange={key => switchComponent(key)} loading={loading}>
      <div className={styles.moduleWrapper}>
        <Typography.Title level={2}>
          NORMA VOLONTARIA PER LASTRE
        </Typography.Title>
        <Typography.Text style={{ fontSize: 17, color: '#898989' }}>
          La Normativa Europea UNI EN 1468:2012 è una norma che specifica i
          requisiti per le lastre grezze di pietra naturale dalle quali si
          ricavano i prodotti per l&apos; impiego in edilizia o nell&apos; arte
          funeraria e per altre applicazioni simili.
          <br /> Si tratta di una norma Volontaria perché le lastre
          semi-lavorate sono definite dalla normativa come prodotti non finiti,
          in quanto non incorporabili in modo permanente in opere da
          costruzione.
        </Typography.Text>
        <Divider />
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          Selezionare la categoria desiderata
        </Typography.Title>
        <Segmented
          block
          options={[
            user.categoryVisibility.includes('granite') && {
              label: 'Graniti (Vedi elenco)',
              value: 'granito',
            },
            user.categoryVisibility.includes('marbre') && {
              label: 'Marmi (Vedi elenco)',
              value: 'marmo',
            },
          ].filter(obj => obj)}
          onChange={val => setActiveCategory(val)}
          value={activeCategory}
        />
        {activeCategory && (
          <Collapse
            ghost
            style={{
              paddingLeft: activeCategory === 'marmo' ? '50%' : 0,
            }}>
            {materialList &&
              materialList
                ?.sort((a, b) => parseInt(a?.order) - parseInt(b?.order))
                ?.map(currentMaterial => {
                  return (
                    <Collapse.Panel
                      key={currentMaterial._id}
                      header={
                        <Typography.Link strong style={{ fontSize: 18 }}>
                          {currentMaterial.name}
                        </Typography.Link>
                      }>
                      <Col style={{ marginLeft: 24, marginTop: -24 }}>
                        <MaterialRow
                          currentMaterial={currentMaterial}
                          activeCategory={activeCategory}
                        />
                      </Col>
                    </Collapse.Panel>
                  )
                })}
          </Collapse>
        )}
      </div>
    </PublicWrapper>
  )
}

const MaterialRow = ({ currentMaterial, activeCategory }) => {
  const [loading, setLoading] = React.useState(false)

  return (
    <Row
      span={24}
      key={currentMaterial._id}
      className={
        activeCategory === 'marmo'
          ? styles.documentRow_marmi
          : styles.documentRow
      }
      onClick={() => {
        setLoading(true)
        downloadMaterial(currentMaterial._id, DOCTYPE)
          .then(() => {
            setLoading(false)
          })
          .catch(() => null)
      }}>
      <div style={{ justifyContent: 'center' }}>
        <FilePdfOutlined style={{ fontSize: 24 }} />
        <Typography.Text strong style={{ marginLeft: 8, fontSize: 16 }}>
          PDF Norma Volontaria {currentMaterial.name}
        </Typography.Text>
      </div>
      <div>
        {loading ? (
          <LoadingOutlined style={{ fontSize: 24 }} />
        ) : (
          <Button
            size="middle"
            loading={loading}
            icon={<DownloadOutlined style={{ fontSize: 20 }} />}
            type="primary"
          />
        )}
      </div>
    </Row>
  )
}

export default NVL
