import client from './client'

export const getUserList = () => {
  return new Promise((resolve, reject) => {
    client
      .get('/users')
      .then(({ data }) => resolve(data))
      .catch(e => reject(e))
  })
}

export const createUser = info => {
  return new Promise((resolve, reject) => {
    client
      .post(`/users`, info)
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}

export const updateUserInfo = (id, info) => {
  return new Promise((resolve, reject) => {
    client
      .put(`/users/${id}`, info)
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}

export const getUserInfo = id => {
  return new Promise((resolve, reject) => {
    client
      .get(`/users/${id}`)
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}

export const deleteUser = id => {
  return new Promise((resolve, reject) => {
    client
      .delete(`/users/${id}`)
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}
