import axios from 'axios'
import client from './client'

export const login = ({ username, password }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/users/login`, {
        username,
        password,
      })
      .then(({ data }) => {
        resolve(data)
      })
      .catch(e => {
        reject(e)
      })
  })
}
