import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './index.css'

import Login from './routes/login'
import Aziende from './routes/aziende'
import Documenti from './routes/documenti'
import Materiali from './routes/materiali'
import Public from './routes/public'
import 'antd/dist/antd.css'
import { adminChecker, privateChecker } from './utils/checker'
import { selectUser } from './redux/user'
import MCE from './routes/public/components/marcaturaCE'
import DDP from './routes/public/components/DichiarazioneDiPrestazione'
import STPO from './routes/public/components/SchedaTecnicaPreOrdine'
import NVL from './routes/public/components/NormaVolontariaLastre'
import ProveInizialiTipo from './routes/public/components/proveInizialiTipo'
import Petrografie from './routes/public/components/petrografie'
import ProvaDin from './routes/public/components/provaDin'
import Home from './routes/dashboard'

const App = () => {
  const userDetails = useSelector(selectUser)
  console.log({ userDetails })

  function navigateChecker() {
    if (userDetails.token) {
      if (adminChecker()) {
        return <Navigate to="/home" state={{ trigger: true }} />
      } else {
        if (privateChecker()) {
          return <Navigate to="/home" state={{ trigger: true }} />
        } else {
          return <Navigate to="/public" state={{ trigger: true }} />
        }
      }
    } else {
      return <Login />
    }
  }

  return (
    <>
      <Routes>
        {userDetails.token && (
          <>
            <Route path="/public" element={<Public />} />
            {userDetails?.user?.documentVisibility === 'all' ? (
              <>
                <Route path="/public/ddp" element={<DDP />} />
                <Route path="/public/ce" element={<MCE />} />
                <Route path="/public/nvl" element={<NVL />} />
                <Route path="/public/stpo" element={<STPO />} />
                <Route
                  path="/public/proveinizialitipo"
                  element={<ProveInizialiTipo />}
                />
                <Route path="/public/petrografie" element={<Petrografie />} />
                <Route path="/public/provadin" element={<ProvaDin />} />
              </>
            ) : (
              <Route path="/public/petrografie" element={<Petrografie />} />
            )}

            {adminChecker() && (
              <>
                <Route path="/home" element={<Home />} />
                <Route path="/aziende" element={<Aziende />} />
                <Route path="/aziende/action" element={<Aziende />} />
                <Route path="/materiali" element={<Materiali />} />
                <Route path="/materiali/action" element={<Materiali />} />
                <Route path="/documenti" element={<Documenti />} />
                <Route path="/documenti/action" element={<Documenti />} />
              </>
            )}
            {privateChecker() && (
              <>
                <Route path="/home" element={<Home />} />
                <Route path="/materiali" element={<Materiali />} />
                <Route path="/materiali/action" element={<Materiali />} />
                <Route path="/documenti" element={<Documenti />} />
                <Route path="/documenti/action" element={<Documenti />} />
              </>
            )}
          </>
        )}
        <Route path="/" element={navigateChecker()} />
        <Route path="*" element={navigateChecker()} />
      </Routes>
    </>
  )
}

export default App
