import {
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  Modal,
  Radio,
  Typography,
} from 'antd'
import React from 'react'
import { downloadMaterialPost } from '../../../../../api/documents'

const CEModal = ({ visible, onClose, lang, material, activeCategory }) => {
  // States
  const [checkOptions, setCheckOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [code, setCode] = React.useState('')
  const [invoice, setInvoice] = React.useState('')
  const [location, setLocation] = React.useState('')
  const [sign, setSign] = React.useState('')

  const RADIO_OPTIONS = [
    {
      label: `Marmette di pietra naturale destinate all' uso di "pavimentazione interna" come da UNI EN 12057:2015`,
      value: '1',
    },
    {
      label: `Marmette di pietra naturale destinate all'uso di "pavimentazione esterna"
      come da UNI EN 12057:2015`,
      value: '2',
    },
    {
      label: `Marmette di pietra naturale destinate all' uso di "rivestimenti interni"
      come da UNI EN 12057:2015`,
      value: '3',
    },
    {
      label: `Marmette di pietra naturale destinate all' uso di "rivestimenti esterni"
      come da UNI EN 12057:2015`,
      value: '4',
    },
    {
      label: `Lastre di pietra naturale destinate all' uso di "pavimentazione interna"
      come da UNI EN 12058:2015`,
      value: '5',
    },
    {
      label: `Lastre di pietra naturale destinate all' uso di "pavimentazione esterna"
      come da UNI EN 12058:2015`,
      value: '6',
    },
    {
      label: `Lastre di pietra naturale destinate all' uso di "rivestimenti interni"
      come da UNI EN 1469:2015`,
      value: '7',
    },
    {
      label: `Lastre di pietra naturale destinate all' uso di "rivestimenti esterni"
      come da UNI EN 1469:2015`,
      value: '8',
    },
    {
      label: `Lastre di pietra naturale destinate all' uso di "pavimentazione esterna"
      come da UNI EN 1341:2013`,
      value: '9',
    },
    {
      label: `Cubetti di pietra naturale destinate all' uso di "pavimentazione esterna"
      come da UNI EN 1342:2013`,
      value: '10',
    },
    {
      label: `Cordoli di pietra naturale destinate all' uso di "pavimentazione esterna"
      come da UNI EN 1343:2013`,
      value: '11',
    },
  ]

  const download = () => {
    setLoading(true)
    downloadMaterialPost(
      material._id,
      'ddp',
      {
        code,
        invoice,
        location,
        sign,
      },
      {
        lang,
        section: checkOptions,
      },
    )
      .then(() => {
        setLoading(false)
      })
      .catch(() => null)
  }

  return (
    <Modal
      title="Generazione documento"
      open={visible}
      footer={null}
      onCancel={() => {
        setLoading(false)
        setCheckOptions([])
        setCode('')
        setSign('')
        setLocation('')
        setInvoice('')
        onClose()
      }}
      style={{
        maxHeight: '75%',
      }}
      width={'80%'}>
      {visible && (
        <div style={{ overflowY: 'auto' }}>
          <Typography.Title level={4}>
            Hai selezionato la prova del {activeCategory} {material.name}. Il
            tempo di creazione del PDF dipende dalla quantità di pagine e dalla
            velocità di connessione ad internet.
          </Typography.Title>
          <Divider />
          <div style={{ width: '50%', marginBottom: 16 }}>
            <Typography.Text>
              Codice di identificazione del prodotto:
            </Typography.Text>
            <Input value={code} onChange={e => setCode(e.target.value)} />
          </div>
          <div style={{ width: '50%' }}>
            <Typography.Text>Fattura/Invoice Num.:</Typography.Text>
            <Input value={invoice} onChange={e => setInvoice(e.target.value)} />
          </div>
          <Divider />
          <Radio.Group
            value={checkOptions}
            style={{ width: '100%' }}
            onChange={e => setCheckOptions(e.target.value)}>
            {RADIO_OPTIONS.map(current => {
              return (
                <Col span={24} key={current.value} style={{ marginBottom: 4 }}>
                  <Radio value={current.value}>
                    <Typography.Text style={{ fontSize: 18 }}>
                      {current.label.split('come da')[0]}
                    </Typography.Text>
                    <br />
                    <Typography.Text
                      style={{ fontSize: 18, fontWeight: 'bold' }}>
                      ({current.label.split('come da ')[1]})
                    </Typography.Text>
                  </Radio>
                </Col>
              )
            })}
          </Radio.Group>
          <Divider />
          <div style={{ width: '50%', marginBottom: 16 }}>
            <Typography.Text>
              Firmato a nome e per conto del fabbricante:
            </Typography.Text>
            <Input value={sign} onChange={e => setSign(e.target.value)} />
          </div>
          <div style={{ width: '50%' }}>
            <Typography.Text>(luogo e data del rilascio):</Typography.Text>
            <Input
              value={location}
              onChange={e => setLocation(e.target.value)}
            />
          </div>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              loading={loading}
              onClick={() => download()}
              type="primary"
              style={{ width: '40%' }}>
              SCARICA
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default CEModal
